import React from 'react';

const ScreenViewPort = ({
  layout,
  handleInputChange,
  isCampaignScreen,
  checked,
}) => {
  const LayoutType = {
    landscape_full: 'Landscape-Full',
    landscape_80_20: 'Landscape-80/20',
    landscape_split: 'Landscape-Split',
    portrait_full: 'Portrait-Full',
    portrait_80_20: 'Portrait-80/20',
    portrait_split: 'Portrait-Split',
  };
  return (
    <div className="scr_layout_container">
      {layout === LayoutType.landscape_full ? (
        <div className="landscape_full_scrn">
          <div className="form-check">
            <label htmlFor="screenViewPort" className="form-check-label">
              View 1
            </label>
            {!isCampaignScreen && (
              <input
                type="radio"
                name="screenViewPort"
                className="form-check-input"
                value="1"
                checked={checked === 1}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      ) : layout === LayoutType.landscape_80_20 ? (
        <div className="landscape_80_20_scrn">
          <div className="view_port ">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 1
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="1"
                  checked={checked === 1}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
          <div className="view_port_two">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 2
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="2"
                  checked={checked === 2}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
        </div>
      ) : layout === LayoutType.landscape_split ? (
        <div className="landscape_split_scrn">
          <div className="view_port">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 1
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="1"
                  checked={checked === 1}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
          <div className="view_port_two">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 2
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="2"
                  checked={checked === 2}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
        </div>
      ) : layout === LayoutType.portrait_full ? (
        <div className="portrait_full_scrn">
          <div className="form-check">
            <label htmlFor="screenViewPort" className="form-check-label">
              View 1
            </label>
            {!isCampaignScreen && (
              <input
                type="radio"
                name="screenViewPort"
                className="form-check-input"
                value="1"
                checked={checked === 1}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      ) : layout === LayoutType.portrait_80_20 ? (
        <div className="portrait_80_20_scrn">
          <div className="view_port">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 1
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="1"
                  checked={checked === 1}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
          <div className="view_port_two">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 2
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="2"
                  checked={checked === 2}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
        </div>
      ) : layout === LayoutType.portrait_split ? (
        <div className="portrait_split_scrn">
          <div className="view_port">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 1
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="1"
                  checked={checked === 1}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
          <div className="view_port_two">
            <div className="form-check">
              <label htmlFor="screenViewPort" className="form-check-label">
                View 2
              </label>
              {!isCampaignScreen && (
                <input
                  type="radio"
                  name="screenViewPort"
                  className="form-check-input"
                  value="2"
                  checked={checked === 2}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        layout === '' || null(<p>No specified layout for this campaign</p>)
      )}
    </div>
  );
};

export default ScreenViewPort;
