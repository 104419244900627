/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const Iframe = () => {
  const queryString = require('query-string');
  // eslint-disable-next-line no-restricted-globals
  const parsed = queryString.parse(location.search);

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <iframe
        width="100%"
        height="100%"
        src={parsed.url}
        frameborder="0"
        allow="accelerometer; autoplay; fullscreen *;  encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true"
      ></iframe>
    </div>
  );
};

export default Iframe;
