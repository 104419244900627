import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { DateTime } from 'luxon';
import 'swiper/css';
// import 'swiper/css/lazy';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '9999',
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1100px',
    width: '90%',
    padding: '0',
    background: 'rgb(26 26 26)',
  },
};
const AdPlayModal = ({
  modalIsOpen,
  onClose,
  adConfigurations,
  currentScreen,
}) => {
  const [adsToBePlayed, setAdsToBePlayed] = useState([]);
  const [currentAdDuration, setCurrentAdDuration] = useState(0);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  function getAdToBePlayed() {
    const toBePlayed = [];
    const currentDay = DateTime.now().weekdayLong;
    const currentTime = DateTime.now().toFormat('HH:mm:ss');

    if (!adConfigurations.data) return;
    for (const ads of adConfigurations.data) {
      const adStartTime = DateTime.fromISO(
        ads.adConfiguration.startTime,
      ).toFormat('HH:mm:ss');
      const adEndTime = DateTime.fromISO(ads.adConfiguration.endTime).toFormat(
        'HH:mm:ss',
      );
      // using luxon
      // check if ad is to be played today by checking the day === today
      // check if ad is to be played now by checking the now is within the start and end time discarding the date
      if (
        ads.adConfiguration.days.includes(currentDay) &&
        currentTime >= adStartTime &&
        currentTime <= adEndTime
      ) {
        toBePlayed.push(ads);
      }
    }
    setAdsToBePlayed(toBePlayed);
  }

  useEffect(() => {
    getAdToBePlayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adConfigurations.data]);

  setInterval(() => {
    getAdToBePlayed();
  }, 120000);

  useEffect(() => {
    const getCurrentAdDuration = () => {
      const currentAd = adsToBePlayed[currentAdIndex || 0];
      const currentAdDuration = currentAd?.adConfiguration?.duration;
      setCurrentAdDuration(currentAdDuration);
    };
    getCurrentAdDuration();
  }, [adsToBePlayed, currentAdIndex]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <div className="slid"> */}
        <h5 className="text-center text-white">
          {currentScreen[0].screenId} - {currentScreen[0].name}
        </h5>

        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          modules={[Autoplay, Pagination, Navigation]}
          onRealIndexChange={(element) =>
            setCurrentAdIndex(element.activeIndex || 0)
          }
          autoplay={{
            delay: currentAdDuration * 1000 || 2000,
            disableOnInteraction: false,
          }}
          className="mySwiper"
        >
          {adsToBePlayed?.length > 0 ? (
            adsToBePlayed?.map((item, index) => (
              <SwiperSlide key={index}>
                {item.adType === 'iframe' ? (
                  <iframe
                    style={{
                      width: '100%',
                      height: '564px',
                      objectFit: 'contain',
                      border: 'none',
                    }}
                    title={item.adId}
                    src={
                      item.adUrl.includes('clock')
                        ? item.adUrl.replace('clock', 'clock2')
                        : item.adUrl.includes('weather')
                        ? item.adUrl.replace('weather', 'weather2')
                        : item.adUrl
                    }
                  />
                ) : item.adType === 'video' ? (
                  <ReactPlayer
                    url={item.adUrl}
                    controls={false}
                    playing={true}
                    muted={true}
                    loop={true}
                    stopOnUnmount={false}
                    loading="lazy"
                    //   style={{ height: '300px' }}
                    width="100%"
                    height="564px"
                  />
                ) : (
                  <img
                    className="d-block w-100"
                    style={{ objectFit: 'cover' }}
                    src={item.adUrl}
                    alt={item.adId}
                    loading="lazy"
                  />
                )}
              </SwiperSlide>
            ))
          ) : (
            <div className="text-center text-white mt-4">No Ads</div>
          )}
        </Swiper>
      </Modal>
    </div>
  );
};

export default AdPlayModal;
