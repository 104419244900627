/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';

const Widgets = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [screens, setScreens] = useState([]);
  const [success, setSuccess] = useState(false);
  const [widget, setWidget] = useState({
    widgetType: '',
    screenId: '',
  });
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const [existingWidgets, setExistingWidgets] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    if (widget.screenId.length > 0) {
      setLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/get-widgets/${widget.screenId}`,
          {
            headers,
          },
        )
        .then((res) => {
          setExistingWidgets(res.data);
          setTimeout(() => setLoading(false), 2000);
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
        });
    }
  }, [token, widget.screenId, success]);

  const handleInputChange = (e) => {
    setWidget((prevDetails) => {
      return { ...prevDetails, [e.target.name]: e.target.value };
    });
    // setCity(screens.filter((screen) => screen._id === e.target.value)[0].city);
  };

  const handleSetWidgetType = (widgetType) => {
    if (widget.screenId !== '') {
      setWidget((prevDetails) => {
        return { ...prevDetails, widgetType };
      });
    } else {
      setMessage({
        type: 'error',
        msg: 'Please select a screen to add widget.',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 6000);
    }
  };

  const handleAddWidget = () => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    if (widget.screenId !== '' || widget.widgetType !== '') {
      setSubmitLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/add/widget`, widget, {
          headers,
        })
        .then((res) => {
          setSubmitLoading(false);
          setSuccess(true);
          setWidget((prevDetails) => {
            return { ...prevDetails, widgetType: '' };
          });
          setMessage({
            type: 'success',
            msg: 'Widget added successfully',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 6000);
          setTimeout(() => {
            setSuccess(false);
          }, 100);
        })
        .catch((err) => {
          setSubmitLoading(false);
          // setTimeout(() => setSubmitLoading(false), 2000);
          setWidget((prevDetails) => {
            return { ...prevDetails, widgetType: '' };
          });
          setMessage({
            type: 'error',
            msg: err.response?.data?.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 6000);
        });
    } else {
      setMessage({
        type: 'error',
        msg: 'Please select a screen to add widget.',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 6000);
    }
  };

  const handleRemoveWidget = () => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    if (widget.screenId !== '' || widget.widgetType !== '') {
      setSubmitLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/remove/widget`, widget, {
          headers,
        })
        .then((res) => {
          setSubmitLoading(false);
          setSuccess(true);
          setWidget((prevDetails) => {
            return { ...prevDetails, widgetType: '' };
          });
          setMessage({
            type: 'success',
            msg: 'Widget removed successfully',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 5000);
          setTimeout(() => {
            setSuccess(false);
          }, 100);
        })
        .catch((err) => {
          setSubmitLoading(false);
          // setTimeout(() => setSubmitLoading(false), 2000);
          setWidget((prevDetails) => {
            return { ...prevDetails, widgetType: '' };
          });
          setMessage({
            type: 'error',
            msg: err.response?.data?.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 5000);
        });
    } else {
      setMessage({
        type: 'error',
        msg: 'Please select a screen to remove widget.',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
    }
  };

  useEffect(() => {
    if (widget.screenId !== '' && widget.widgetType !== '') {
      handleAddWidget();
    }
  }, [widget.screenId, widget.widgetType]);

  useEffect(() => {
    if (widget.screenId !== '' && widget.widgetType !== '') {
      handleRemoveWidget();
    }
  }, [widget.screenId, widget.widgetType]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {submitLoading && <Spinner />}

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Widgets</div>
          <select
            id="screen"
            onChange={handleInputChange}
            className="widget-screens"
            name="screenId"
          >
            <option value="">Choose Screen</option>
            {screens.map((screen, i) => (
              <option key={i} value={screen._id}>
                {screen.name}
              </option>
            ))}
          </select>

          {widget.screenId.length > 0 ? (
            <div>
              <h4 className="widget-category">Existing Widgets</h4>

              {existingWidgets.length > 0 ? (
                <div className="widgets-container">
                  {/* TIME WIDGET BOX */}
                  {/* TIME WIDGET BOX */}

                  {existingWidgets.map(
                    (widget, i) =>
                      widget.widgetType === 'time' && (
                        <div key={i} className="widgets-box1">
                          <div className="widgets-title">
                            <h3>digital clock</h3>
                          </div>

                          <div className="widgets-contained">
                            <iframe
                              loading="lazy"
                              title="time"
                              src={`${process.env.REACT_APP_BASEURL_CLOCK}`}
                              name="folham clock"
                              scrolling="No"
                              width="100%"
                              style={{
                                border: '1px solid #DFDFDF',
                                minHeight: '370px',
                              }}
                            ></iframe>
                            <button
                              className="addnew-btn"
                              onClick={() => handleSetWidgetType('time')}
                            >
                              Remove Widget
                            </button>
                          </div>
                        </div>
                      ),
                  )}
                  {/* WEATHER WIDGET BOX */}
                  {/* WEATHER WIDGET BOX */}
                  {existingWidgets.map(
                    (widget, i) =>
                      widget.widgetType === 'weather' && (
                        <div key={i} className="widgets-box1">
                          <div className="widgets-title">
                            <h3>weather app</h3>
                          </div>

                          <div className="widgets-contained">
                            <iframe
                              loading="lazy"
                              title="weather"
                              src={`${process.env.REACT_APP_BASEURL_WEATHER}`}
                              name="folham weather app"
                              scrolling="No"
                              width="100%"
                              style={{
                                border: '1px solid #DFDFDF',
                                minHeight: '370px',
                              }}
                            ></iframe>
                            <button
                              className="addnew-btn"
                              onClick={() => handleSetWidgetType('weather')}
                            >
                              Remove Widget
                            </button>
                          </div>
                        </div>
                      ),
                  )}

                  {/* LOCATION WIDGET BOX */}
                  {/* LOCATION WIDGET BOX */}
                  {existingWidgets.map(
                    (widget, i) =>
                      widget.widgetType === 'road_traffic' && (
                        <div key={i} className="widgets-box1">
                          <div className="widgets-title">
                            <h3>distance app</h3>
                          </div>
                          <div className="widgets-contained">
                            <div className="map-container">
                              <iframe
                                title="Location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253682.45932641064!2d3.1438713553142636!3d6.548376810791543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1654884223124!5m2!1sen!2sng"
                                width="100%"
                                height="100%"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>

                            <select
                              id="screen"
                              name="screen"
                              style={{ margin: '1rem 0' }}
                            >
                              <option value="screen">
                                Location 1 e.g Abuja
                              </option>
                            </select>

                            <select
                              id="screen"
                              name="screen"
                              style={{ margin: '1rem 0' }}
                            >
                              <option value="screen">
                                Location 2 e.g Challenge
                              </option>
                            </select>

                            <select
                              id="screen"
                              name="screen"
                              style={{ marginTop: '1rem' }}
                            >
                              <option value="screen">
                                Location 3 e.g Challenge
                              </option>
                            </select>

                            <button
                              className="addnew-btn"
                              onClick={() =>
                                handleSetWidgetType('road_traffic')
                              }
                            >
                              Add Widget
                            </button>
                          </div>
                        </div>
                      ),
                  )}
                </div>
              ) : (
                <p
                  style={{
                    marginBottom: '50px',
                    fontSize: '16px',
                    fontWeight: '300',
                  }}
                >
                  The selected screen has no existing widgets. You can add
                  widgets below.
                </p>
              )}
            </div>
          ) : (
            <p
              style={{
                marginBottom: '50px',
                fontSize: '16px',
                fontWeight: '300',
              }}
            >
              Please select screen to view existing widgets.
            </p>
          )}

          <div>
            {existingWidgets.length < 2 && (
              <h4 className="widget-category">New Widgets</h4>
            )}

            <div className="widgets-container">
              {/* TIME WIDGET BOX */}
              {/* TIME WIDGET BOX */}

              {existingWidgets.map(
                (w, i) =>
                  w.widgetType === 'weather' &&
                  existingWidgets.length === 1 && (
                    <div key={i} className="widgets-box1">
                      <div className="widgets-title">
                        <h3>digital clock</h3>
                      </div>

                      <div className="widgets-contained">
                        <iframe
                          loading="lazy"
                          title="time"
                          src={`${process.env.REACT_APP_BASEURL_CLOCK}`}
                          name="folham clock"
                          scrolling="No"
                          width="100%"
                          style={{
                            border: '1px solid #DFDFDF',
                            minHeight: '370px',
                          }}
                        ></iframe>
                        <button
                          className="addnew-btn"
                          onClick={() => handleSetWidgetType('time')}
                        >
                          Add Widget
                        </button>
                      </div>
                    </div>
                  ),
              )}

              {existingWidgets.map(
                (w, i) =>
                  w.widgetType === 'time' &&
                  existingWidgets.length === 1 && (
                    <div key={i} className="widgets-box1">
                      <div className="widgets-title">
                        <h3>weather app</h3>
                      </div>

                      <div className="widgets-contained">
                        <iframe
                          loading="lazy"
                          title="weather"
                          src={`${process.env.REACT_APP_BASEURL_WEATHER}`}
                          name="folham weather app"
                          scrolling="No"
                          width="100%"
                          style={{
                            border: '1px solid #DFDFDF',
                            minHeight: '370px',
                          }}
                        ></iframe>
                        <button
                          className="addnew-btn"
                          onClick={() => handleSetWidgetType('weather')}
                        >
                          Add Widget
                        </button>
                      </div>
                    </div>
                  ),
              )}

              {existingWidgets.length === 0 && (
                <>
                  <div className="widgets-box1">
                    <div className="widgets-title">
                      <h3>digital clock</h3>
                    </div>

                    <div className="widgets-contained">
                      <iframe
                        loading="lazy"
                        title="time"
                        src={`${process.env.REACT_APP_BASEURL_CLOCK}`}
                        name="folham clock"
                        scrolling="No"
                        width="100%"
                        style={{
                          border: '1px solid #DFDFDF',
                          minHeight: '370px',
                        }}
                      ></iframe>
                      <button
                        className="addnew-btn"
                        onClick={() => handleSetWidgetType('time')}
                      >
                        Add Widget
                      </button>
                    </div>
                  </div>
                  {/* WEATHER WIDGET BOX */}
                  {/* WEATHER WIDGET BOX */}

                  <div className="widgets-box1">
                    <div className="widgets-title">
                      <h3>weather app</h3>
                    </div>

                    <div className="widgets-contained">
                      <iframe
                        loading="lazy"
                        title="weather"
                        src={`${process.env.REACT_APP_BASEURL_WEATHER}`}
                        name="folham weather app"
                        scrolling="No"
                        width="100%"
                        style={{
                          border: '1px solid #DFDFDF',
                          minHeight: '370px',
                        }}
                      ></iframe>
                      <button
                        className="addnew-btn"
                        onClick={() => handleSetWidgetType('weather')}
                      >
                        Add Widget
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* LOCATION WIDGET BOX */}
              {/* LOCATION WIDGET BOX */}

              {/* <div className="widgets-box1">
              <div className="widgets-title">
                <h3>distance app</h3>
              </div>
              <div className="widgets-contained">
                <div className="map-container">
                  <iframe
                    title="Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253682.45932641064!2d3.1438713553142636!3d6.548376810791543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1654884223124!5m2!1sen!2sng"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>

                <select id="screen" name="screen" style={{ margin: "1rem 0" }}>
                  <option value="screen">Choose Screen</option>
                  <option value="screen">Choose Screen</option>
                  <option value="screen">Landscape Full</option>
                  <option value="screen">Landscape 80/20</option>
                  <option value="screen">Landscape Spilt</option>
                  <option value="screen">Potrait Full</option>
                  <option value="screen">Potrait 80/20</option>
                  <option value="screen">Potrait Spilt</option>
                </select>

                <select id="screen" name="screen" style={{ margin: "1rem 0" }}>
                  <option value="screen">Location 1 e.g Abuja</option>
                </select>

                <select id="screen" name="screen" style={{ margin: "1rem 0" }}>
                  <option value="screen">Location 2 e.g Challenge</option>
                </select>

                <select id="screen" name="screen" style={{ marginTop: "1rem" }}>
                  <option value="screen">Location 3 e.g Challenge</option>
                </select>

                <button className="addnew-btn">Add Widget</button>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widgets;
