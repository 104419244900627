import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';

import {
  faEye,
  faEnvelope,
  faLock,
  faCheckCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import welcome from '../img/login.png';
import logo from '../img/logo.png';

const Login = () => {
  const [submit, setSubmit] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginDetails, setLogindetails] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    setLogindetails((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmit(true);

    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/auth/login`, loginDetails)

      .then((res) => {
        setMessage({
          type: 'success',
          msg: `${res.data.message}. Please wait...`,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
          setSubmit(false);
          setLoading(false);
          localStorage.setItem('accessToken', res.data.access_token);
          localStorage.setItem('user', 'true');
          window.location.reload();
          navigate('/home');
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setSubmit(false);
        if (err.message === 'Network Error') {
          setMessage({
            type: 'error',
            msg: err.message,
          });
        }
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 8000);
      });
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  const myFunction = () => {
    var x = document.getElementById('myInput');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };

  return (
    <div className="login">
      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.type === 'error' ? message.msg : 'log in successful'}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && submit && <Spinner />}

      <div className="box1">
        <img src={welcome} alt="" className="box-image" />
      </div>

      <div className="box2">
        <div className="logo-login">
          <img src={logo} alt="Folham" />
        </div>

        <h1>Welcome back!</h1>
        <p>Enter the form below to log in to your account</p>

        <div className="box2-mini">
          <div className="bx-mini-cnt">
            <form onSubmit={login}>
              <div className="mini1" style={{ marginTop: '1rem' }}>
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    id="email"
                    name="email"
                    value={loginDetails.email}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <div className="mini1" style={{ marginTop: '1rem' }}>
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="password"
                    name="password"
                    value={loginDetails.password}
                    id="myInput"
                    placeholder="Password"
                    required
                  />
                </div>
                <FontAwesomeIcon
                  icon={faEye}
                  className="box1-icon"
                  style={{ color: '#797D87' }}
                  onClick={() => myFunction()}
                />
              </div>

              <div className="mini2" style={{ marginTop: '2rem' }}>
                <label>
                  <input type="checkbox" name="rememberme" /> Remember Me
                </label>

                <Link to="forgot-password">Forgot password?</Link>
              </div>

              <button title="Log In" className="box1-btn">
                Log In
              </button>
            </form>
          </div>
        </div>

        <div className="box-bottom">
          Do not have an account yet?{' '}
          <span>
            {' '}
            <Link to="/signup"> Create an account</Link>{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
