import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

import logo from '../img/logo.png';
import forgot from '../img/forgot.svg';
import Spinner from '../components/Spinner';

const ForgotPassword = () => {
  const [details, setDetails] = useState({
    email: '',
    password: '',
  });
  const [submit, setSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (details) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}/auth/password-reset`,
          details,
        )
        .then((res) => {
          setSubmit(false);
          if (res.status === 201) {
            toast.success('Password reset successful');
            window.location.href = '/';
          }
          console.log(res);
        })
        .catch((err) => {
          setSubmit(false);
          if (err.response.data.error === 'Bad Request') {
            toast.error(
              'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long.',
              {
                duration: 7000,
              },
            );
          } else {
            toast.error(err.response.data.message);
          }
        });
    }
  };

  return (
    <div className="forgot-pass">
      <Toaster />
      {submit && <Spinner />}
      <div
        className="d-flex justify-content-between"
        style={{ height: '100vh' }}
      >
        <div className="w-50">
          <img src={forgot} alt="logo" className="w-100" />
        </div>
        <div className="w-50 bg-white height-full p-12 ">
          <img src={logo} alt="logo" className="w-25 m-auto d-block" />

          <div className="text-center mb-5">
            <h1>Reset Password</h1>
            <p>Enter your email and new password to continue</p>
          </div>

          <form className="w-75 m-auto" onSubmit={handleSubmit}>
            <div className="form-group mb-2">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                required
                onChange={handleInputChange}
              />
            </div>

            <button className="box1-btn m-0">Reset</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
