import React, { useState, useEffect } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../components/Spinner';

const AddNewAdCompany = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const [addAdCompany, setaddAdCompany] = useState({
    companyName: '',
    companyAbbreviation: '',
    adAccountManager: '',
    adAccountManagerEmail: '',
    adAccountManagerPhoneNumber: '',
    accountServiceManagerId: '',
  });

  const handleInputChange = (e) => {
    setaddAdCompany((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  const addCompanyAd = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setLoading(true);
    if (
      addAdCompany.companyName === '' ||
      addAdCompany.companyEmail === '' ||
      addAdCompany.companyPhoneNumber === '' ||
      addAdCompany.companyWebsite === '' ||
      addAdCompany.adAccountManager === '' ||
      addAdCompany.adAccountManagerEmail === '' ||
      addAdCompany.adAccountManagerPhoneNumber === ''
    ) {
      setMessage({
        type: 'error',
        msg: 'Please fill all the fields',
      });
      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
      setLoading(false);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}/ad-company/create`,
          addAdCompany,
          { headers },
        )
        .then((res) => {
          setLoading(false);
          setMessage({
            type: 'success',
            msg: 'Ad company created successfully!',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
            window.location.reload();
          }, 6000);

          setaddAdCompany({
            companyName: '',
            companyAbbreviation: '',
            adAccountManager: '',
            adAccountManagerEmail: '',
            adAccountManagerPhoneNumber: '',
          });
        })
        .catch((err) => {
          setLoading(false);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 8000);
        });
    }
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/all-users`, { headers })
      .then((res) => {
        setUsers(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Add New Ad Company</div>

          <div className="second-title">Add New Ad Company</div>

          <div className="addnew-container">
            <div className="addnew-content">
              <form onSubmit={addCompanyAd}>
                <label htmlFor="fname">Company Name</label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="name"
                  name="companyName"
                  placeholder="Company Name"
                  value={addAdCompany.companyName}
                />

                <label htmlFor="docodeb">
                  Company Code (This can be the company's abbreviation e.g ABBV)
                </label>
                <input
                  onChange={handleInputChange}
                  value={addAdCompany.companyAbbreviation}
                  type="text"
                  name="companyAbbreviation"
                  placeholder="Company Code"
                />

                <div className="third-title">Company Ad Account Manager </div>

                <label htmlFor="adAccountManager">
                  Ad Account Manager Name
                </label>
                <input
                  onChange={handleInputChange}
                  value={addAdCompany.adAccountManager}
                  type="text"
                  name="adAccountManager"
                  placeholder="Full Name"
                />

                <label htmlFor="email">Email </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  name="adAccountManagerEmail"
                  value={addAdCompany.adAccountManagerEmail}
                  placeholder="Email Address"
                />

                <label htmlFor="fname">Phone Number </label>
                <input
                  onChange={handleInputChange}
                  type="tel"
                  name="adAccountManagerPhoneNumber"
                  placeholder="Phone Number"
                  value={addAdCompany.adAccountManagerPhoneNumber}
                />

                <div className="third-title">Account Officer </div>

                <label htmlFor="accountServiceManagerId">
                  Select Account Officer
                </label>
                <select
                  name="accountServiceManagerId"
                  id="accountServiceManagerId"
                  onChange={handleInputChange}
                >
                  <option value="">Select Account Officer</option>
                  {users.map((user, index) => (
                    <option key={index} value={user._id}>
                      {user.firstName} {user.lastName}
                    </option>
                  ))}
                </select>

                {/* <label htmlFor="email">Email </label>
                <input
                  onChange={handleInputChange}
                  type="text"
                  name="adAccountManagerEmail"
                  value={addAdCompany.adAccountManagerEmail}
                  placeholder="Email Address"
                />

                <label htmlFor="fname">Phone Number </label>
                <input
                  onChange={handleInputChange}
                  type="tel"
                  name="adAccountManagerPhoneNumber"
                  placeholder="Phone Number"
                  value={addAdCompany.adAccountManagerPhoneNumber}
                /> */}

                <button className="addnew-btn" title="Create new Ad Company">
                  Add Company
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAdCompany;
