import React from 'react'

const Spinner = () => {
  return (
    <div className="loader">
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span> 
        <span></span>
        <span></span>
        <span></span>
        <h2>Loading...</h2>
      </div>
    </div>
  )
}

export default Spinner
