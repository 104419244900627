import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';

import {
  faEnvelope,
  faCheckCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import create from '../img/signup.png';
import logo from '../img/logo.png';

const SignUp = () => {
  const [submit, setSubmit] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [signUpDetails, setSignUpDetails] = useState({
    email: '',
    type: 'signup',
  });

  const handleInputChange = (e) => {
    setSignUpDetails((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };

  const signup = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmit(true);

    axios
      .post(
        `${process.env.REACT_APP_API_BASEURL}/auth/request-otp`,
        signUpDetails,
      )

      .then((res) => {
        setMessage({
          type: 'success',
          msg: res.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
          setLoading(false);
          setSubmit(false);
          navigate('/verify-otp');
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setSubmit(false);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 8000);
      });
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div className="login">
      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={
            message.type === 'error'
              ? message.msg
              : 'Go to your email to copy your OTP'
          }
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && submit && <Spinner />}

      <div className="box2-signup">
        <div className="logo-login">
          <img src={logo} alt="Folham" />
        </div>

        <h1>Create Account</h1>
        <div className="box-bottom">
          Already a member?{' '}
          <span>
            {' '}
            <Link to="/"> Log in</Link>{' '}
          </span>
        </div>

        <div className="box2-mini">
          <div className="bx-mini-cnt">
            <form onSubmit={signup}>
              <div className="mini1" style={{ margin: '2rem 0' }}>
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    id="email"
                    name="email"
                    value={signUpDetails.email}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn-signup"
                disabled={loading}
                style={{ marginBottom: '2rem' }}
              >
                Request OTP
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="box1">
        <img src={create} alt="" className="box-image" />
      </div>
    </div>
  );
};

export default SignUp;
