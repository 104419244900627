import './App.scss';
import React from 'react';
import MainRoutes from './MainRoutes';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '4c57f734-b89f-4382-b40d-eb54a3b024d5',
  clientToken: 'pub8a0f413092f923b17ecbd007817d92d0',
  site: 'datadoghq.eu',
  service: 'folham-frontend',
  env: 'production',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

const App = () => {
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
};

export default App;
