/* eslint-disable no-unused-vars */
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import SideBar from '../components/SideBar';
import ContentNavbar from '../components/ContentNavbar';

const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleGetOrganizations = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/organization/findAll`)
      .then((res) => {
        setOrganizations(res.data);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 3000);
      });
  };
  useEffect(() => {
    handleGetOrganizations();
  }, []);

  const handleDeleteOrganization = (id) => {
    setLoading(true);
    const token = localStorage.getItem('accessToken');
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .delete(
        `${process.env.REACT_APP_API_BASEURL}/organization/delete/${id}`,
        { headers },
      )
      .then((res) => {
        setMessage({
          type: 'success',
          msg: res.data.message,
        });
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });
        setTimeout(() => setLoading(false), 3000);
      });
  };

  //create a prompt to confirm if a user wants to delete an organization
  const handleDeletePrompt = (id) => {
    const confirm = window.confirm(
      'Are you sure you want to delete this organization?',
    );
    if (confirm) {
      handleDeleteOrganization(id);
    } else {
      return;
    }
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />
      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Organizations</div>

          <div className="second-title d-flex align-items-center justify-content-between flex-wrap gap-3">
            <span>All Organizations</span>{' '}
            <button
              onClick={() => setShowForm(!showForm)}
              className="btn btn-primary"
            >
              {showForm ? 'Back' : 'Create Organization'}
            </button>
          </div>

          <div className="addnew-container">
            {!showForm ? (
              <div className="addnew-content">
                {organizations.map((organization) => (
                  <div key={organization._id} className="user-box active">
                    <div className="user-details">
                      <div className="icon-box">
                        <FontAwesomeIcon icon={faBox} className="icon" />
                      </div>
                      <div>
                        <h4>{organization.name}</h4>
                      </div>
                    </div>
                    <div className="btns">
                      {/* <button
                      type="button"
                      title={`Edit ${organization.name}`}
                      className="user-btn"
                      onClick={() => {}}
                    >
                      Edit
                    </button> */}
                      <button
                        type="button"
                        title={`Delete ${organization.name}`}
                        className="user-btn delete-btn"
                        onClick={() => handleDeletePrompt(organization._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="addnew-content">
                <AddNewOrganization
                  setShowForm={setShowForm}
                  handleGetOrganizations={handleGetOrganizations}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizations;

const AddNewOrganization = ({ setShowForm, handleGetOrganizations }) => {
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const [loading, setLoading] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState({
    name: '',
  });
  const handleInputChange = (e) => {
    setOrganizationDetails({
      ...organizationDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateOrganization = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_BASEURL}/organization/create`,
        organizationDetails,
        { headers },
      )
      .then((res) => {
        setLoading(false);
        handleGetOrganizations();
        setMessage({
          type: 'success',
          msg: res.data.message || 'Organization created successfully',
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
          setShowForm(false);
        }, 3000);
      })
      .catch((err) => {
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="row justify-content-center">
      {loading && <Spinner />}
      <div className="col-md-6">
        {message.type && (
          <div
            className={`alert ${
              message.type === 'success' ? 'alert-success' : 'alert-danger'
            }`}
            role="alert"
          >
            {message.msg}
          </div>
        )}
        <form onSubmit={handleCreateOrganization}>
          <label htmlFor="fname">Name</label>
          <input
            onChange={handleInputChange}
            type="text"
            id="name"
            name="name"
            placeholder="Organization Name"
            value={organizationDetails.name}
            className="mt-1"
          />
          <button className="btn btn-primary py-2 mt-3" type="submit">
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              'Create Organization'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};
