import React, { useState, useEffect, useRef } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import { Link } from 'react-router-dom';
import SideBar from '../components/SideBar';
import {
  faCirclePlus,
  faCheckCircle,
  faWarning,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';
import AdPlayModal from '../components/AdPlayModal';
import Modal from 'react-modal';
import ScreenViewPort from '../components/ScreenViewPort';

const CampaignSchedule = ({ setSelectedCampaign }) => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [adAccountCompany, setAdAccountCompany] = useState([]);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const [scheduledCampaign] = useState([]);
  const [dragStartIndex, setdragStartIndex] = useState(null);
  const [screens, setScreens] = useState([]);
  const [scheduleScreens, setScheduleScreens] = useState('');
  const [screenCampaigns, setScreenCampaigns] = useState([]);
  const [adAccountGroup] = useState([]);
  const [screenIdNameGroup, setScreenIdNameGroup] = useState([]);
  const [noCampaignScreens, setNoCampaignScreens] = useState(false);
  const [screenViewPort, setScreenViewPort] = useState(1);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
    if (localStorage.getItem('lastScreenId')) {
      setScheduleScreens(localStorage.getItem('lastScreenId'));
      setScreenViewPort(1);
    }
  }, []);

  const handleInputChange = (e) => {
    localStorage.setItem('lastScreenId', e.target.value);
    setScheduleScreens(e.target.value);
    setScreenViewPort(1);
  };

  const handleViewChange = (e) => {
    setScreenViewPort(parseInt(e.target.value));
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        setTimeout(() => setLoading(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  // useEffect(() => {
  //   const headers = {
  //     accept: 'application/json',
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   };
  //   if (scheduleScreens.length > 0) {
  //     setScreenLoading(true);

  //     axios
  //       .get(
  //         `${process.env.REACT_APP_API_BASEURL}/campaign/screen/${scheduleScreens}`,
  //         {
  //           headers,
  //         },
  //       )
  //       .then((res) => {
  //         setScreenCampaigns(res.data);
  //         setTimeout(() => setScreenLoading(false), 2000);
  //       })
  //       .catch((err) => {
  //         setTimeout(() => setScreenLoading(false), 2000);
  //       });
  //   } else {
  //     setScreenCampaigns([]);
  //   }
  // }, [token, scheduleScreens, adDeleted]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    if (scheduleScreens.length > 0) {
      setScreenLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/ad-accounts/screen/${scheduleScreens}`,
          {
            headers,
          },
        )
        .then((res) => {
          setScreenCampaigns(res.data);
          setTimeout(() => setScreenLoading(false), 2000);
        })
        .catch((err) => {
          setTimeout(() => setScreenLoading(false), 2000);
        });
    } else {
      setScreenCampaigns([]);
    }
  }, [token, scheduleScreens]);

  const handleSendToDevice = () => {
    if (scheduleScreens.length > 0) {
      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      if (scheduleScreens.length > 0) {
        setLoading(true);

        axios
          .get(
            `${process.env.REACT_APP_API_BASEURL}/campaign/send-to-device/${scheduleScreens}`,
            {
              headers,
            },
          )
          .then((res) => {
            setLoading(false);
            setMessage({
              type: 'success',
              msg: res.data.message,
            });

            setTimeout(() => {
              setMessage({
                type: '',
                msg: '',
              });
            }, 5000);
          })
          .catch((err) => {
            setLoading(false);
            setMessage({
              type: 'error',
              msg: err.response.data.message,
            });

            setTimeout(() => {
              setMessage({
                type: '',
                msg: '',
              });
            }, 5000);
          });
      }
    } else {
      setMessage({
        type: 'error',
        msg: 'Please select a screen',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
    }
  };

  useEffect(() => {
    if (scheduleScreens !== '') {
      screenCampaigns.forEach((camp, i) => {
        let filteredAdAccount = adAccountCompany.filter(
          (ad, i) => ad._id === camp.adAccount,
        );
        if (adAccountGroup.length < screenCampaigns.length) {
          adAccountGroup.push(filteredAdAccount[0]);
        } else {
          adAccountGroup.splice(0, adAccountGroup.length);
          adAccountGroup.push(filteredAdAccount[0]);
        }
      });
    }
  }, [adAccountCompany, screenCampaigns, scheduleScreens, adAccountGroup]);

  useEffect(() => {
    if (scheduleScreens !== '') {
      screenCampaigns.forEach((camp, i) => {
        camp.accounts.forEach((acc) => {
          let filteredScreenName = screens?.filter(
            (ad, i) => ad._id === acc?.adScreen,
          );
          let filteredScreenId = screens?.filter(
            (ad, i) => ad._id !== acc?.adScreen,
          );
          setScreenIdNameGroup(filteredScreenName[0]);
          setNoCampaignScreens(filteredScreenId[0]);
        });
      });
    }
  }, [
    screens,
    scheduleScreens,
    screenCampaigns,
    screenIdNameGroup,
    noCampaignScreens,
  ]);

  useEffect(() => {
    const currentScreenCampaign = screenCampaigns.filter(
      (screen) => screen.screenViewPort === screenViewPort,
    );

    if (currentScreenCampaign.length > 0) {
      let list = [...currentScreenCampaign];
      list.map((item, index) => (item.order = index));
      list.forEach((l, i) => {
        if (scheduledCampaign.length < list.length) {
          l.accounts.forEach((acc, o) => {
            scheduledCampaign.push({
              adAccountId: acc._id,
              order: o,
            });
          });
        } else {
          scheduledCampaign.splice(0, scheduledCampaign.length);
          l.accounts.forEach((acc, o) => {
            scheduledCampaign.push({
              adAccountId: acc._id,
              order: o,
            });
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenCampaigns]);

  const handleScheduleCampaign = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setSaveLoading(true);

    if (scheduleScreens === '') {
      setSaveLoading(false);

      setMessage({
        type: 'error',
        msg: 'Please select a screen',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
    } else if (scheduledCampaign.length === 0) {
      setSaveLoading(false);
      setMessage({
        type: 'error',
        msg: 'Please select at least one campaign',
      });
      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
    } else {
      const sets = new Set();
      const uniqueCampaign = [];
      for (const item of scheduledCampaign) {
        if (!sets.has(item.adAccountId)) {
          sets.add(item.adAccountId);
          uniqueCampaign.push(item);
        }
      }

      axios
        .put(
          `${process.env.REACT_APP_API_BASEURL}/ad-accounts/update-sequence-order`,
          uniqueCampaign,
          { headers },
        )
        .then((res) => {
          setTimeout(() => setSaveLoading(false), 2000);
          // setAdAccountCompany(res.data);
          setMessage({
            type: 'success',
            msg: 'Updated successfully',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 8000);
        })
        .catch((err) => {
          setTimeout(() => setSaveLoading(false), 2000);
          setMessage({
            type: 'error',
            msg: err.message || err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 10000);
        });
    }
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, { headers })
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setAdAccountCompany(res.data);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  const itemRef = useRef(null);

  const onDragStart = (e, index) => {
    e.dataTransfer.effectedAllowed = 'move';
    e.dataTransfer.setDragImage(e.target, 50000, 50000);

    let ghostNode = e.target.cloneNode(true);

    ghostNode.style.position = 'absolute';
    ghostNode.style.zIndex = 9999;

    ghostNode.style.top = e.pageY - e.target.offsetHeight / 2 + 'px';
    ghostNode.style.left = e.pageX - e.target.offsetWidth / 2 + 'px';

    ghostNode.style.height = e.target.offsetHeight + 'px';
    ghostNode.style.width = e.target.offsetWidth + 'px';

    ghostNode.style.opacity = '0.8';
    ghostNode.style.cursor = 'grabbing';
    ghostNode.style.pointerEvents = 'none';
    ghostNode.style.background = '#efefef';
    ghostNode.style.transform = 'scale(1.02)';
    // ghostNode.style.transition = "all 0.3s ease-in-out";

    ghostNode.id = 'ghostNode';

    document.body.prepend(ghostNode);

    e.target.classList.add('dragstart');
    setdragStartIndex(index);
  };
  const onDrag = (e) => {
    let ghostNode = document.querySelector('#ghostNode');
    ghostNode.style.cursor = 'grabbing';
    ghostNode.style.top = e.pageY - e.target.offsetHeight / 2 + 'px';
    ghostNode.style.left = e.pageX - e.target.offsetWidth / 2 + 'px';
  };

  // event when drag end
  const onDragEnd = (e) => {
    document.querySelector('#ghostNode').remove();

    e.target.classList.remove('dragstart');
  };

  const onDragEnter = (e) => {
    e.target.classList.add('dragover');
  };

  const onDragLeave = (e) => {
    e.target.classList.remove('dragover');
  };

  const onDragOver = (e) => e.preventDefault();

  const onDrop = (e, dropIndex) => {
    e.target.classList.remove('dragover');

    const currentScreenCampaign = screenCampaigns.filter(
      (screen) => screen.screenViewPort === screenViewPort,
    );
    const dragItem = currentScreenCampaign[0].accounts[dragStartIndex];

    let list = [...currentScreenCampaign[0].accounts];
    list.splice(dragStartIndex, 1);

    list.splice(dropIndex, 0, dragItem);
    setScreenCampaigns((prevState) => {
      return prevState.map((screen) => {
        if (screen.screenViewPort === screenViewPort) {
          return {
            ...screen,
            accounts: list,
          };
        }
        return screen;
      });
    });
  };

  const getCampaign = (campaign) => {
    setSelectedCampaign(campaign);
  };

  const [adConfigurations, setAdConfigurations] = useState([]);

  const getAdImageAndConfigurations = () => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // setLoading(true);
    const currentScreen = screens.filter(
      (screen) => screen._id === scheduleScreens,
    );

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/public-adverts/ad-image-and-configuration/${currentScreen[0].deviceId}`,
        {
          headers,
        },
      )
      .then((res) => {
        setAdConfigurations(res.data);
        setTimeout(() => setLoading(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    if (scheduleScreens.length > 0) {
      getAdImageAndConfigurations();
      setIsOpen(true);
      return;
    }

    setMessage({
      type: 'error',
      msg: 'Please select a screen',
    });

    setTimeout(() => {
      setMessage({
        type: '',
        msg: '',
      });
    }, 5000);
  }

  //   function afterOpenModal() {
  //     // references are now sync'd and can be accessed.
  //     subtitle.style.color = '#f00';
  //   }

  function closeModal() {
    setIsOpen(false);
  }

  Modal.setAppElement('#root');

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}
      {modalIsOpen && (
        <AdPlayModal
          modalIsOpen={modalIsOpen}
          onClose={closeModal}
          adConfigurations={adConfigurations}
          currentScreen={screens.filter(
            (screen) => screen._id === scheduleScreens,
          )}
        />
      )}

      <div className="main-content">
        <div className="content">
          <div className="campaignschedule">
            <div className="title-group">
              <div className="campaign-first-title">campaign schedule</div>
              <span>
                <button
                  onClick={handleSendToDevice}
                  className="campaignschedule-button"
                  title="Send to device"
                >
                  {saveLoading ? <Spinner /> : 'Send to device'}
                </button>
              </span>
            </div>

            <div className="first-campaignschedule">
              <label htmlFor="ad-screen">Select Screen:</label>
              <select
                name="adScreen"
                id="ad-screen"
                value={scheduleScreens}
                onChange={handleInputChange}
              >
                <option value="">Select Screen ID/Name</option>
                {screens.map((screen, i) => {
                  return (
                    <option key={i} value={screen._id}>
                      {screen.screenId} - {screen.name}
                    </option>
                  );
                })}
              </select>

              {/* <button >
                {" "}
                <FontAwesomeIcon icon={faArrowsRotate} />{" "}
              </button> */}
            </div>

            <div className="second-campaignschedule">
              <h3>Screen Layout</h3>

              {screens.filter((screen) => screen._id === scheduleScreens)
                .length < 1 ? (
                <p>Select a screen</p>
              ) : (
                screens
                  .filter((screen) => screen._id === scheduleScreens)
                  .map((screen) => (
                    <ScreenViewPort
                      key={screen._id}
                      layout={screen.layout}
                      handleInputChange={handleViewChange}
                      checked={screenViewPort}
                    />
                  ))
              )}
            </div>

            <div className="home-container">
              {/* FIRST BOX */}
              {/* FIRST BOX */}

              <div className="home-contained">
                <div className="box1-title">
                  sequence campaign in screen
                  <span>
                    <button
                      onClick={handleScheduleCampaign}
                      className="campaignschedule-button"
                      title="Save rearranged campaign"
                    >
                      {saveLoading ? <Spinner /> : 'Save'}
                    </button>
                  </span>
                </div>
                <div className="campaign1-container">
                  <div className="campaignscdule-minibox">
                    <p>Drag and drop to arrange ad accounts</p>
                    <Link to="/createcampaign">
                      <span>
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          style={{ marginRight: '.5rem' }}
                        />
                        Campaign
                      </span>
                    </Link>
                  </div>

                  {!screenLoading ? (
                    <div className="campaignschedule-smallbox">
                      {!loading &&
                        scheduleScreens.length > 0 &&
                        screenCampaigns.map(
                          (ad, i) =>
                            ad?.screenViewPort === screenViewPort &&
                            ad.accounts.map((acc, j) => (
                              <div
                                key={j}
                                className="smallbox1"
                                draggable="true"
                                ref={itemRef}
                                onDragStart={(e) => onDragStart(e, j)}
                                onDrag={onDrag}
                                onDragEnd={onDragEnd}
                                onDragEnter={onDragEnter}
                                onDragLeave={onDragLeave}
                                onDragOver={onDragOver}
                                onDrop={(e) => onDrop(e, j)}
                                title={acc.name}
                                // title={ad.accounts.map((acc) => acc.name)}
                              >
                                <p>{acc.name}</p>
                                {/* <span>
                              <Link
                                onClick={() => setEditableCampaign(ad)}
                                to="/editcampaign"
                                title={`Edit ${ad.name}`}
                              >
                                <FontAwesomeIcon
                                  icon={faPenToSquare}
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '1rem',
                                  }}
                                />
                              </Link>
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                onClick={() => deleteAd(ad)}
                                style={{ cursor: 'pointer' }}
                                title={`Delete ${ad.name}`}
                              />
                            </span> */}
                              </div>
                            )),
                        )}
                      {!loading &&
                        screenCampaigns.length === 0 &&
                        scheduleScreens !== '' && (
                          <div className="smallbox1">
                            <p>No Campaign</p>
                          </div>
                        )}
                      {!loading && scheduleScreens === '' && (
                        <div
                          className="smallbox1"
                          style={{ cursor: 'default' }}
                        >
                          <p>Please select Screen</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <h4>Please wait...</h4>
                  )}
                </div>
              </div>

              {/* SECOND BOX */}
              {/* SECOND BOX */}

              <div className="home-contained">
                <div className="box1-title">
                  <span>sequence ad accounts</span>{' '}
                  <div
                    onClick={openModal}
                    style={{ cursor: 'pointer' }}
                    role="button"
                    title="Play Ads"
                  >
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <span style={{ fontSize: '14px', marginLeft: '.5rem' }}>
                      Play
                    </span>
                  </div>
                </div>
                <div className="campaign1-container">
                  <div className="campaignscdule-minibox2">
                    <div className="campaign-boxtitle">
                      {!screenLoading &&
                      scheduleScreens.length !== 0 &&
                      screenCampaigns.length > 0 ? (
                        <div>
                          {screenIdNameGroup.resolution &&
                          screenIdNameGroup.resolution !== '' ? (
                            <>
                              {screenIdNameGroup.screenId} -{' '}
                              {screenIdNameGroup.name} (
                              {screenIdNameGroup.resolution})
                            </>
                          ) : (
                            <>
                              {screenIdNameGroup.screenId} -{' '}
                              {screenIdNameGroup.name} (
                              {screenIdNameGroup.width} X{' '}
                              {screenIdNameGroup.height})
                            </>
                          )}
                        </div>
                      ) : null}
                      {!screenLoading && scheduleScreens.length === 0 && (
                        <div>Select Screen</div>
                      )}
                    </div>

                    <div className="campaignschedule-smallbox2">
                      <div className="smallbox2-blue">
                        <h2>Ad Account</h2>
                        {/* <h2>campaigns</h2> */}
                      </div>

                      {!screenLoading &&
                        scheduleScreens.length > 0 &&
                        screenCampaigns.map((campaign) =>
                          campaign.accounts.map(
                            (acc, i) =>
                              campaign.screenViewPort === screenViewPort && (
                                <Link
                                  to={`/view_ad_account/${acc.name.replace(
                                    /\s+/g,
                                    '_',
                                  )}/${acc._id}`}
                                  state={{ adAccount: acc }}
                                  onClick={() => getCampaign(acc._id)}
                                  key={i}
                                  className="smallbox2 campaign-box"
                                  title={`View ${acc.name}`}
                                >
                                  <h2>{acc.name}</h2>
                                  <p> {adAccountGroup[i]?.name} </p>
                                </Link>
                              ),
                          ),
                        )}
                      {!screenLoading &&
                        (screenCampaigns.length === 0 ||
                          scheduleScreens.length === 0) && (
                          <div className="smallbox2">
                            <p>no ad account</p>
                            {/* <h2>no campaign</h2> */}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSchedule;
