import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import ToastNotification from "./ToastNotification";
import Spinner from "./Spinner";
import useCountdown from './useCounter'

import {
faLock,
faEnvelope,
  faCheckCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import create from "../img/signup.png";
import logo from "../img/logo.png";

const VerifyOtp = () => {

  const endTime = new Date().getTime() + 60000 * 2; // 2 minutes
  const [timeLeft ] = useCountdown(endTime);

  const minutes = Math.floor(timeLeft / 60000) % 60;
  const seconds = Math.floor(timeLeft / 1000) % 60;


  const [submit, setSubmit] = useState(false);
  const [message, setMessage] = useState({
    type: "",
    msg: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [signUpDetails, setSignUpDetails] = useState({
    email: "",
    code: "",
  });

  const handleInputChange = (e) => {
    setSignUpDetails((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };
 
  const signup = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmit(true);

    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/auth/verify-otp`, signUpDetails)

      .then((res) => {
        setMessage({
          type: "success",
          msg: res.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: "",
            msg: "",
          });
          setLoading(false);
          setSubmit(false);
          navigate("/create-account");

        }, 3000);
       
      })
      .catch((err) => {
        setLoading(false);
        setSubmit(false);
        setMessage({
          type: "error",
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: "",
            msg: "",
          });
        }, 8000);
      });
  };

  const handleCloseNotification = () => {
    setMessage({
      type: "",
      msg: "",
    });
  };


  return (
    <div className="login">
      {message.msg !== "" && (
        <ToastNotification
          type={message.type}
          text={message.type === "error" ? message.msg : "OTP verification successfull "}
          onclick={handleCloseNotification}
          icon={message.type === "success" ? faCheckCircle : faWarning}
        />
      )}

      {loading && submit && <Spinner />}

      <div className="box2-signup">
        <div className="logo-login">
          <img src={logo} alt="Folham" />
        </div>

        <h1>Create Account</h1>
        <div className="box-bottom">
          Already a member?{" "}
          <span>
            {" "}
            <Link to="/"> Log in</Link>{" "}
          </span>
        </div>

        <div className="box2-mini">
          <div className="bx-mini-cnt">
            <form onSubmit={signup}>

            <div className="mini1" style={{ marginTop: '2rem'}}>
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: "#797D87", fontSize: "16px" }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="email"
                    value={signUpDetails.email}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <div className="mini1" style={{ marginTop: '2rem'}}>
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ color: "#797D87", fontSize: "16px" }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="code"
                    value={signUpDetails.code}
                    placeholder="Enter OTP"
                    required
                  />
                </div>
              </div>

              <div className="mini2">
                <p>
                  OTP expires in {`${minutes} : ${seconds}`}
                </p>
                <span ><Link to='/signup'> Resend OTP </Link></span>
              </div>

              <button type="submit" className="btn-signup" disabled={loading}>
                Verify OTP
              </button> 
            </form>
          </div>
        </div>
      </div>

      <div className="box1">
        <img src={create} alt="" className="box-image" />
      </div>
    </div>
  );
};

export default VerifyOtp;
