import React, { useState, useEffect } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import axios from 'axios';

const Support = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  // const logout = () => {

  //   const headers = {
  //     'accept': 'application/json',
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   }
  //   axios.put(`${process.env.REACT_APP_API_BASEURL}/auth/logout`, {headers})
  //   .then( (res) => {
  //     (res.data)
  //   })
  //   .catch( (err) => {
  //     (err)
  //   })
  // }

  const [support, setSupport] = useState({
    message: '',
    type: 'feedback',
  });

  const handleInputChange = (e) => {
    setSupport((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };

  const supportFeedback = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/feedback`, support, {
        headers,
      })
      .then((res) => {
        setSupport({
          message: '',
          type: 'feedback',
        });
        alert(res.statusText);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      <div className="main-content">
        <div className="content">
          <div className="first-title">Support</div>

          <div className="second-title">Support</div>

          <div className="addnew-container">
            <div className="addnew-content">
              <form action="/action_page.php" onSubmit={supportFeedback}>
                <label htmlFor="message">Message</label>
                <textarea
                  onChange={handleInputChange}
                  name="message"
                  type="feedback"
                  placeholder="Tell us the error you've encountered."
                  id="message"
                  value={support.message}
                  required
                />

                <button className="support-btn">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
