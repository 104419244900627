import React, { useEffect, useState } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import {
  faCheckCircle,
  faEdit,
  faTrashCan,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Spinner from '../components/Spinner';
import ToastNotification from '../components/ToastNotification';
import ToggleSwitch from '../components/ToggleSwitch';
import ScreenViewPort from '../components/ScreenViewPort';
import Accordion from 'react-bootstrap/Accordion';
// import { AccordionItem, Table, Button } from 'react-bootstrap';

const ScreenCampaign = ({ selectedScreen, setSelectedScreen }) => {
  const LayoutType = {
    landscape_full: 'Landscape-Full',
    landscape_80_20: 'Landscape-80/20',
    landscape_split: 'Landscape-Split',
    portrait_full: 'Portrait-Full',
    portrait_80_20: 'Portrait-80/20',
    portrait_split: 'Portrait-Split',
  };

  // const screenResolutionDetails = [
  //   // {
  //   //   resolutionType:"SD (Standard Definition)",
  //   //   commonName:	"480p",
  //   //   aspectRatio: "4:3",
  //   //   pixelSize: "640 x 480"
  //   // },
  //   // {
  //   //   resolutionType:"HD (High Definition)",
  //   //   commonName:	"720p",
  //   //   aspectRatio: "16:9",
  //   //   pixelSize: "1280 x 720"
  //   // },
  //   // {
  //   //   resolutionType:"Full HD (FHD)",
  //   //   commonName:	"1080p",
  //   //   aspectRatio: "16:9",
  //   //   pixelSize: "1920 x 1080"
  //   // },
  //   // {
  //   //   resolutionType:"QHD (Quad HD)",
  //   //   commonName:	"1440p",
  //   //   aspectRatio: "16:9",
  //   //   pixelSize: "2560 x 1440"
  //   // },
  //   // {
  //   //   resolutionType:"2K video",
  //   //   commonName:	"1080p",
  //   //   aspectRatio: "1:1.77",
  //   //   pixelSize: "2048 x 1080"
  //   // },
  //   // {
  //   //   resolutionType:"4K video or Ultra HD (UHD)",
  //   //   commonName:	"4K or 2160p",
  //   //   aspectRatio: "1:1.9",
  //   //   pixelSize: "3840 x 2160"
  //   // },
  //   // {
  //   //   resolutionType:"8K video or Full Ultra HD",
  //   //   commonName:	"8K or 4320p",
  //   //   aspectRatio: "16∶9",
  //   //   pixelSize: "7680 x 4320"
  //   // },
  // ]

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [screens, setScreens] = useState([]);
  const [screencampaigns, setScreenCampaigns] = useState([]);
  // const [scrollingAds, setScrollingAds] = useState([]);
  const [adAccount, setAdAccount] = useState([]);
  const [campaignAdAccount, setCampaignAdAccount] = useState([]);
  // const [editingRow, setEditingRow] = useState(null);
  // const [screenId, setScreenId] = useState("");
  const [allScreens, setAllScreens] = useState({
    screenId: '',
    name: '',
    resolution: '',
    height: '',
    width: '',
    deviceId: '',
    layout: '',
  });
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const [showForm, setShowForm] = useState(false);
  const [switchToggle, setSwitchToggle] = useState(false);
  const [scrollingAdsToggle, setScrollingAdsToggle] = useState(false);

  const isCampaignScreen = true;

  const handleInputChange = (e) => {
    if (e.target.type === 'number') {
      setAllScreens((prevDetails) => {
        return { ...prevDetails, [e.target.name]: parseInt(e.target.value) };
      });
    } else {
      setAllScreens((prevDetails) => {
        return { ...prevDetails, [e.target.name]: e.target.value };
      });
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        setTimeout(() => setLoading(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token, switchToggle]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // let screenFilter = screens.filter((item, i) => item._id === id);
    // setSelectedScreen(screenFilter[0]);

    // if (selectedScreen._id !== undefined) {
    //   let screenFilter = screens.filter((item, i) => item._id === selectedScreen?._id);
    //   setSelectedScreen(screenFilter[0]);
    // }

    if (Object.keys(selectedScreen).length > 0) {
      // setLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/campaign/screen/${selectedScreen._id}`,
          {
            headers,
          },
        )
        .then((res) => {
          setScreenCampaigns(res.data);
          setTimeout(() => setLoading(false), 2000);
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
        });
    }
  }, [token, selectedScreen, switchToggle, screens]);

  const handleScreenChange = (id) => {
    let screenFilter = screens.filter((item, i) => item._id === id);
    if (id === '') {
      setSelectedScreen({});
      setScreenCampaigns([]);
    } else {
      setSelectedScreen(screenFilter[0]);
    }

    // setScreenId(id);
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/campaign/screen/${id}`, {
        headers,
      })
      .then((res) => {
        setScreenCampaigns(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });

    //FOR THE SCROLLING AD///
    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/scrollingAds/all-scrolling-ads-details/${id}`,
        {
          headers,
        },
      )
      .then((res) => {
        // setScrollingAds(res.data.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, {
        headers,
      })
      .then((res) => {
        setAdAccount(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  useEffect(() => {
    let fil = [];
    if (screencampaigns.length !== 0) {
      screencampaigns.forEach((screencampaign) => {
        let a = adAccount.filter(
          (item, i) => item?._id === screencampaign?.adAccount?._id,
        );
        fil.push(a[0]);
      });
      setCampaignAdAccount(fil);
    }
  }, [adAccount, screencampaigns]);

  useEffect(() => {
    if (Object.keys(selectedScreen).length !== 0) {
      setAllScreens({
        screenId: selectedScreen.screenId,
        name: selectedScreen.name,
        width: selectedScreen.width,
        height: selectedScreen.height,
        resolution: selectedScreen.resolution,
        deviceId: selectedScreen.deviceId,
        layout: selectedScreen.layout,
      });
    } else {
      setAllScreens({
        screenId: '',
        name: '',
        width: '',
        height: '',
        resolution: '',
        deviceId: '',
        layout: '',
      });
    }
  }, [selectedScreen]);

  const handleEdit = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setSubmitLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/ad-screens/screen/${selectedScreen._id}/update`,
        allScreens,
        {
          headers,
        },
      )
      .then((res) => {
        setSubmitLoading(false);
        setTimeout(() => setLoading(false), 2000);

        setAllScreens({
          screenId: '',
          name: '',
          resolution: '',
          deviceId: '',
          layout: '',
        });
        document.querySelectorAll('input[type=radio]').forEach(function (el) {
          el.checked = false;
        });

        setMessage({
          type: 'success',
          msg: 'Campaign edited successfully!',
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
          // navigate("/allscreens");
          setShowForm(false);

          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        setSubmitLoading(false);
        setTimeout(() => setLoading(false), 2000);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 8000);
      });
  };

  useEffect(() => {
    setSwitchToggle(selectedScreen?.sendEmailNotification);
  }, [selectedScreen]);

  const handleToggleNotifications = (val) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/ad-screens/toggle-email-notification/${selectedScreen._id}/${val}`,
        {
          headers,
        },
      )
      .then((res) => {
        setSwitchToggle(val);
        setSelectedScreen(res.data);
        if (res.data.sendEmailNotification) {
          setMessage({
            type: 'success',
            msg: 'Notification on',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 3000);
        } else {
          setMessage({
            type: 'error',
            msg: 'Notification off',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 3000);
        }
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  };

  const handleToggleScrollingAds = (val) => {
    setScrollingAdsToggle(val);
  };

  const deleteScreen = () => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // let adId = screenCampaigns.filter((a) => a._id === ad._id);
    setLoading(true);
    // setAdDeleted(false);

    axios
      .delete(
        `${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screen/${selectedScreen._id}/delete`,
        { headers },
      )
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setMessage({
          type: 'success',
          msg: 'Screen deleted',
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
          window.location.reload();
        }, 3000);
        // setAdDeleted(true);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setMessage({
          type: 'error',
          msg: 'Screen not deleted',
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 3000);
        // setAdDeleted(false);
      });
  };

  // const MyTable = ({
  //   // screencampaigns,
  //   // campaignAdAccount,
  //   // onEdit,
  //   onDelete,
  // }) => {
  //   const [editingRow, setEditingRow] = useState(null);

  //   const handleEdit = (campaignName) => {
  //     setEditingRow(campaignName);
  //   };

  //   const handleDelete = (campaignIndex) => {
  //     onDelete(campaignIndex); // Pass deletion logic to parent component
  //   };
  // };

  // const EditableCell = ({ value, onChange, isEditing }) => {
  //   return (
  //     <td onDoubleClick={() => handleEdit(value)}>
  //       {isEditing ? (
  //         <input type="text" value={value} onChange={onChange} />
  //       ) : (
  //         <span>{value}</span>
  //       )}
  //     </td>
  //   );
  // };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  // const handleDelete = (index) => {
  // console.log(index);
  // };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Screen campaign</div>

          <div className="screencampaign">
            {/* <label htmlFor="screen">Select Screen:</label> */}
            <select
              onChange={(e) => handleScreenChange(e.target.value)}
              id="screen"
              name="screen"
              style={{ marginTop: '1rem' }}
            >
              <option value="">Select Screen</option>
              {screens.map((screen, i) => (
                <option
                  key={i}
                  selected={screen._id === selectedScreen?._id}
                  value={screen._id}
                >
                  {screen.name}
                </option>
              ))}
            </select>

            <div className="screen-container">
              <div className="screen-title">
                <h2>
                  {selectedScreen &&
                    Object.keys(selectedScreen).length !== 0 &&
                    selectedScreen.name}
                </h2>
                {/* <p>
                  <FontAwesomeIcon
                    icon={faPlay}
                    style={{ marginRight: '.8rem' }}
                  />
                  View in Player{' '}
                </p> */}
              </div>

              <div className="screen-contained">
                {!loading && screencampaigns.length !== 0 ? (
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <table>
                        <Accordion.Header>
                          <thead>
                            <tr>
                              <th>Campaigns</th>
                              <th>Ad Accounts</th>
                            </tr>
                          </thead>
                        </Accordion.Header>
                        <tbody>
                          <Accordion.Body>
                            {screencampaigns.map((screencampaign, i) => {
                              return (
                                <tr
                                  key={i}
                                  style={{ borderBottom: '1px solid #797D87' }}
                                >
                                  <td>{screencampaign?.name}</td>
                                  <td>
                                    {campaignAdAccount[i]?.name} <br />
                                  </td>
                                </tr>
                              );
                            })}
                          </Accordion.Body>
                        </tbody>
                      </table>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  <tr>
                    <th>No Campaign</th>
                  </tr>
                )}
              </div>

              <div className="screen-contained details">
                {Object.keys(selectedScreen).length !== 0 ? (
                  <div>
                    <div className="details-heading">
                      <h3>Screen Details</h3>
                      <div className="icon-group">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="edit-icon"
                          title="Edit screen"
                          onClick={() => setShowForm(!showForm)}
                        />
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className="edit-icon"
                          title="Delete screen"
                          onClick={deleteScreen}
                        />
                        <span>Notifications:</span>
                        <ToggleSwitch
                          name="notification"
                          switchToggle={switchToggle}
                          setSwitchToggle={setSwitchToggle}
                          handlerFunction={handleToggleNotifications}
                          value={selectedScreen.sendEmailNotification}
                        />
                        <span>Scrolling Banner:</span>
                        <ToggleSwitch
                          name="scrollingAds"
                          switchToggle={scrollingAdsToggle}
                          setSwitchToggle={setScrollingAdsToggle}
                          handlerFunction={handleToggleScrollingAds}
                          value={selectedScreen.scrollingAds}
                        />
                      </div>
                    </div>
                    {!showForm && (
                      <div className="screen_detail_containar">
                        <div>
                          <p>
                            <b>Screen Name:</b> {selectedScreen.name}
                          </p>
                          <p>
                            <b>Layout:</b> {selectedScreen.layout}
                          </p>
                          {selectedScreen.resolution &&
                          selectedScreen.resolution !== '' ? (
                            <p>
                              <b>Screen Output Size(W x H):</b>{' '}
                              {selectedScreen.resolution}
                            </p>
                          ) : (
                            <p>
                              <b>Screen Output Size(W x H):</b>{' '}
                              {selectedScreen.width} x {selectedScreen.height}
                            </p>
                          )}

                          <p>
                            <b>Screen ID:</b> {selectedScreen.screenId}
                          </p>
                          <p>
                            <b>Device ID:</b> {selectedScreen.deviceId}
                          </p>
                        </div>
                        <div className="layot_selected">
                          <h3>Selected Layout</h3>
                          <ScreenViewPort
                            layout={selectedScreen.layout}
                            isCampaignScreen={isCampaignScreen}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <h3 className="details-heading">No screen selected</h3>
                )}
                {showForm && (
                  <div>
                    <form onSubmit={handleEdit}>
                      <div className="details-heading">
                        <h3>Edit Screen ({selectedScreen.name})</h3>
                      </div>
                      <div className="sc-sf">
                        <div className="edit-screen">
                          <input
                            onChange={handleInputChange}
                            type="text"
                            name="screenId"
                            value={allScreens.screenId}
                            placeholder="Screen ID e.g FLH2"
                          />
                          <input
                            onChange={handleInputChange}
                            type="text"
                            name="name"
                            value={allScreens.name}
                            placeholder="Screen Name"
                          />
                          <input
                            onChange={handleInputChange}
                            type="number"
                            name="height"
                            value={allScreens.height}
                            placeholder="Height"
                          />
                          <input
                            onChange={handleInputChange}
                            type="number"
                            name="width"
                            value={allScreens.width}
                            placeholder="Width"
                          />
                          {/* <select onChange={handleInputChange} name="resolution" id="resolution">
                              <option value="">Select screen resolution</option>
                              {
                                screenResolutionDetails.map((resolution) => (
                                  <option selected={resolution.pixelSize === selectedScreen.resolution} value={resolution.pixelSize}>{resolution.pixelSize}</option>
                                ))
                              }
                            </select> */}
                          <input
                            onChange={handleInputChange}
                            type="text"
                            name="deviceId"
                            value={allScreens.deviceId}
                            placeholder="Unique Device ID"
                          />
                        </div>
                        <div className="layout">
                          <h2>Choose Screen Layout</h2>

                          <div className="layout-screens">
                            <div className="first-layouts">
                              <div className="landscape-full">
                                <div className="layout1">View</div>
                                <div className="landscape-title">
                                  Landscape Full
                                </div>
                                <input
                                  type="radio"
                                  value={LayoutType.landscape_full}
                                  name="layout"
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.landscape_full,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>

                              <div className="landscape-full">
                                <div className="layout3">
                                  <div className="layout3-80"> View 1</div>
                                  <div className="layout3-20"> View 2</div>
                                </div>
                                <div className="landscape-title">
                                  Landscape 80/20
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.landscape_80_20}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.landscape_80_20,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>
                              <div className="landscape-full">
                                <div className="layout3">
                                  <div className="layout3-1"> View 1</div>
                                  <div className="layout3-2"> View 2</div>
                                </div>
                                <div className="landscape-title">
                                  Landscape Split
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.landscape_split}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.landscape_split,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>
                            </div>

                            <div className="first-layouts">
                              <div className="landscape-full">
                                <div className="layout1">View</div>
                                <div className="landscape-title">
                                  Landscape - Vertical Full
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.portrait_full}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.portrait_full,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>

                              <div className="landscape-full">
                                <div className="layout1">
                                  <div className="layout2-1"> View 1</div>
                                  <div className="layout2-2"> View 2</div>
                                </div>
                                <div className="landscape-title">
                                  Landscape - Vertical 80/20
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.portrait_80_20}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.portrait_80_20,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>

                              <div className="landscape-full">
                                <div className="layout1">
                                  <div className="potrait3-1"> View 1</div>
                                  <div className="potrait3-2"> View 2</div>
                                </div>
                                <div className="landscape-title">
                                  Landscape - Vertical Spilt
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.portrait_split}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.portrait_split,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>
                            </div>
                            <div className="first-layouts">
                              <div className="landscape-full">
                                <div className="potrait1">View</div>
                                <div className="landscape-title">
                                  Potrait Full
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.portrait_full}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.portrait_full,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>

                              <div className="landscape-full">
                                <div className="potrait1">
                                  <div className="layout2-1"> View 1</div>
                                  <div className="layout2-2"> View 2</div>
                                </div>
                                <div className="landscape-title">
                                  Potrait 80/20
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.portrait_80_20}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.portrait_80_20,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>

                              <div className="landscape-full">
                                <div className="potrait1">
                                  <div className="potrait3-1"> View 1</div>
                                  <div className="potrait3-2"> View 2</div>
                                </div>
                                <div className="landscape-title">
                                  Potrait Spilt
                                </div>
                                <input
                                  type="radio"
                                  name="layout"
                                  value={LayoutType.portrait_split}
                                  onChange={handleInputChange}
                                  {...(allScreens.layout.includes(
                                    LayoutType.portrait_split,
                                  )
                                    ? { checked: true }
                                    : {})}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="addnew-btn" title="Update screen">
                        {submitLoading ? <Spinner /> : 'Update Screen'}
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenCampaign;
