import { BrowserRouter, Routes, Route } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './pages/Login';
import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoutes from './components/PublicRoutes';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import AddNewAdCompany from './pages/AddNewAdCompany';
import AddUser from './pages/AddUser';
import Support from './pages/Support';
import AdAccount from './pages/AdAccount';
import Widgets from './pages/Widgets';
import Vacancy from './pages/Vacancy';
import CreateCampaign from './pages/CreateCampaign';
import ScreenCampaign from './pages/ScreenCampaign';
import Ads from './pages/Ads';
import ActivityLog from './components/ActivityLog';
import CampaignSchedule from './pages/CampaignSchedule';
import EditCampaign from './pages/EditCampaign';
import AllScreens from './pages/AllScreens';
import GroupScreen from './pages/GroupScreen';
import ScrollToTop from './components/ScrollToTop';
import VerifyOtp from './components/VerifyOtp';
import CreateAccount from './components/CreateAccount';
import ErrorPage from './pages/ErrorPage';
import ViewCampaign from './pages/ViewCampaign';
import DeviceLog from './pages/DeviceLog';
import AdAccountCompanies from './pages/AdAccountCompanies';
// import Clock from './pages/Clock';
import Weather from './pages/Weather';
import Iframe from './pages/Iframe';
import DarkClock from './pages/DarkClock';
import Organizations from './pages/Organizations';
import ForgotPassword from './pages/ForgotPassword';

const MainRoutes = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  // const [user, setUser] = useState(
  //   false || window.localStorage.getItem("user") === "true"
  // );
  // const [prof, setProf] = useState({});
  const [profile, setProfile] = useState({});
  const [editableCampaign, setEditableCampaign] = useState({});
  const [selectedScreen, setSelectedScreen] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState('');

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/profile`, { headers })
      .then((res) => {
        // setProf(res.data);
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem('profile')) {
      setProfile(JSON.parse(localStorage.getItem('profile')));
    } else {
      setTimeout(() => {
        setProfile(JSON.parse(localStorage.getItem('profile')));
      }, 3000);
    }
  }, []);

  // useEffect(() => {
  //   setProfile(JSON.parse(localStorage.getItem("profile")))
  // }, [])

  return (
    <div className="contain">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="" element={<PublicRoutes />}>
              <Route path="/" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="verify-otp" element={<VerifyOtp />} />
              <Route path="create-account" element={<CreateAccount />} />
              {/* <Route path="widget/clock" element={<Clock />} /> */}
              <Route path="widget/clock" element={<DarkClock />} />
              <Route path="widget/weather/*" element={<Weather />} />
              <Route path="ad-player" element={<Iframe />} />
              <Route path="*" element={<ErrorPage />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route path="" element={<ProtectedRoutes />}>
              <Route path="home" element={<Home profile={profile} />} />

              <Route path="addnewad" element={<AddNewAdCompany />} />
              <Route
                path="adduser"
                element={
                  profile?.privilege === 'superAdmin' ||
                  (profile?.privilege === 'admin' &&
                    profile?.organizationId) ? (
                    <AddUser />
                  ) : (
                    <ErrorPage />
                  )
                }
              />
              <Route
                path="ad-account-companies"
                element={
                  profile?.privilege === 'superAdmin' ||
                  (profile?.privilege === 'admin' &&
                    profile?.organizationId) ? (
                    <AdAccountCompanies />
                  ) : (
                    <ErrorPage />
                  )
                }
              />
              <Route
                path="organizations"
                element={
                  profile?.privilege === 'superAdmin' ||
                  (profile?.privilege === 'admin' &&
                    profile?.organizationId) ? (
                    <Organizations />
                  ) : (
                    <ErrorPage />
                  )
                }
              />

              <Route path="support" element={<Support />} />
              <Route
                path="adaccount"
                element={
                  <AdAccount
                    selectedCampaign={selectedCampaign}
                    setSelectedCampaign={setSelectedCampaign}
                  />
                }
              />
              <Route
                path="widget/*"
                element={
                  profile?.privilege === 'superAdmin' ||
                  (profile?.privilege === 'admin' &&
                    profile?.organizationId) ? (
                    <Widgets />
                  ) : (
                    <ErrorPage />
                  )
                }
              />

              {/* <Route path="widget/clock2" element={<Clock />} /> */}
              <Route path="widget/clock2" element={<DarkClock />} />
              <Route path="widget/weather2/*" element={<Weather />} />
              <Route path="ad-player2" element={<Iframe />} />
              <Route path="vacancy" element={<Vacancy />} />
              <Route path="activitylog" element={<ActivityLog />} />
              <Route path="devicelog" element={<DeviceLog />} />
              <Route path="createcampaign" element={<CreateCampaign />} />
              <Route
                path="screencampaign"
                element={
                  profile?.privilege === 'superAdmin' ||
                  profile?.privilege === 'admin' ||
                  profile?.organizationId ? (
                    <ScreenCampaign
                      selectedScreen={selectedScreen}
                      setSelectedScreen={setSelectedScreen}
                    />
                  ) : (
                    <ErrorPage />
                  )
                }
              />
              <Route path="ads" element={<Ads />} />
              <Route
                path="campaignschedule"
                element={
                  <CampaignSchedule
                    setEditableCampaign={setEditableCampaign}
                    setSelectedCampaign={setSelectedCampaign}
                  />
                }
              />
              <Route
                path="view_ad_account/*"
                element={
                  <ViewCampaign
                    selectedCampaign={selectedCampaign}
                    setSelectedCampaign={setSelectedCampaign}
                    setEditableCampaign={setEditableCampaign}
                  />
                }
              />
              <Route
                path="editcampaign"
                element={
                  <EditCampaign
                    editableCampaign={editableCampaign}
                    setEditableCampaign={setEditableCampaign}
                  />
                }
              />
              <Route
                path="allscreens"
                element={
                  profile?.privilege === 'superAdmin' ||
                  profile?.privilege === 'admin' ||
                  profile?.organizationId ? (
                    <AllScreens
                      selectedScreen={selectedScreen}
                      setSelectedScreen={setSelectedScreen}
                    />
                  ) : (
                    <ErrorPage />
                  )
                }
              />
              <Route path="groupscreen" element={<GroupScreen />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};

export default MainRoutes;
