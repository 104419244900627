/* eslint-disable react-hooks/exhaustive-deps */
import {
  faArrowLeftLong,
  faCheckCircle,
  faPenToSquare,
  faTrashCan,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import Spinner from '../components/Spinner';
import ToastNotification from '../components/ToastNotification';

const ViewCampaign = ({
  selectedCampaign,
  setSelectedCampaign,
  setEditableCampaign,
}) => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [adDeleted, setAdDeleted] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  // useEffect(() => {
  //   const headers = {
  //     accept: 'application/json',
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   };
  //   setLoading(true);

  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASEURL}/campaign/${selectedCampaign}/get-campaign-and-adverts`,
  //       { headers },
  //     )
  //     .then((res) => {
  //       setTimeout(() => setLoading(false), 2000);
  //       setCampaignDetails(res.data);
  //     })
  //     .catch((err) => {
  //       setTimeout(() => setLoading(false), 2000);
  //     });
  // }, [token, selectedCampaign]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/ad-accounts/get-campaign/${selectedCampaign}`,
        { headers },
      )
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setCampaignDetails(res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token, selectedCampaign, navigate, adDeleted]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/campaign/campaigns`, {
        headers,
      })
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setCampaigns(res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, { headers })
      .then((res) => {
        setAdAccounts(res.data);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 3000);
      });
  }, [token]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  // useEffect(() => {
  //   window.location.pathname = `/viewcampaign/${selectedCampaign}`;

  // }, [selectedCampaign]);

  const setUrlState = (id) => {
    setSelectedCampaign(id);
    adAccounts.forEach((adAccount) => {
      if (adAccount._id === id) {
        navigate(
          `/view_ad_account/${adAccount.name.replace(/\s+/g, '_')}/${
            adAccount._id
          }`,
          { state: adAccount },
        );
      }
    });
  };

  useEffect(() => {
    let path = location.pathname.split('/');
    if (path.length === 4) {
      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      setLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/ad-accounts/get-campaign/${path[3]}`,
          { headers },
        )
        .then((res) => {
          setTimeout(() => setLoading(false), 2000);
          setCampaignDetails(res.data);
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
        });
      setSelectedCampaign(path[3]);
    }
  }, [location.pathname, token]);

  const deleteCampaign = (id) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // let adId = screenCampaigns.filter((a) => a._id === ad._id);

    setLoading(true);
    setAdDeleted(false);
    // (adId);

    axios
      .delete(`${process.env.REACT_APP_API_BASEURL}/ads/${id}/delete`, {
        headers,
      })
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(true);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(false);
      });
  };

  return (
    <div className="view-campaign">
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">
            Ad Account -{' '}
            {location.state?.adAccount?.adAccount?.adAccountName ||
              location.state?.adAccountName}
          </div>

          <div className="go-back">
            <FontAwesomeIcon icon={faArrowLeftLong} />
            <Link to="/campaignschedule">Back</Link>
          </div>

          <select
            onChange={(e) => setUrlState(e.target.value)}
            className="select-campaign"
            name="select-campaign"
            id="select-campaign"
          >
            <option value="">Select Ad Account to view campaigns</option>
            {adAccounts.map((account, i) => (
              <option
                selected={account._id === selectedCampaign}
                key={i}
                value={account._id}
              >
                {account.name}
              </option>
            ))}
          </select>

          {!loading && (
            <div>
              <div className="second-title">Campaigns</div>

              <div className="addnew-container" style={{ padding: '20px' }}>
                <div className="addnew-content">
                  {campaignDetails.length > 0 ? (
                    // campaign.adverts.map((advert, i) =>
                    campaignDetails.map((ad, i) => (
                      <div key={i} className="ad-thumbnail">
                        {ad.advertType === 'picture' && (
                          <div className="img">
                            <img src={ad.advertUrl} alt="" />
                          </div>
                        )}
                        {ad.advertType === 'video' && (
                          <div className="vid">
                            <video src={ad.advertUrl} controls alt="" />
                          </div>
                        )}
                        <div className="ad-details">
                          <h4>{ad.campaignName}</h4>
                          <span>
                            <Link
                              to="/editcampaign"
                              onClick={() =>
                                setEditableCampaign(
                                  campaigns.filter(
                                    (campaign) =>
                                      campaign._id === ad.campaignId,
                                  )[0],
                                )
                              }
                              title={`Edit ${ad.campaignName}`}
                              className="ico"
                              style={{ color: '#939393', cursor: 'pointer' }}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </Link>
                            <span
                              onClick={() => deleteCampaign(ad.advertId)}
                              title={`Delete ${ad.campaignName}`}
                              className="ico"
                              style={{ color: '#E74A3B', cursor: 'pointer' }}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </span>
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    // )
                    <h3>No Campaigns for this Ad Account.</h3>
                  )}
                </div>
              </div>
            </div>
          )}
          {!loading && campaignDetails.length === 0 && (
            <div className="no-campaign">
              <h3>No Campaigns to view</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCampaign;
