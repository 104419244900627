import React, { useState, useEffect, useMemo } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import DatalistInput from 'react-datalist-input';
import {
  faArrowsRotate,
  faChevronRight,
  faCheckCircle,
  faWarning,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

const AllScreens = ({ selectedScreen, setSelectedScreen }) => {
  const [allScreens, setAllScreens] = useState({
    screenId: '',
    name: '',
    height: '',
    width: '',
    city: '',
    longitude: '',
    latitude: '',
    deviceId: '',
    layout: '',
    organizationId: '',
  });
  const LayoutType = {
    landscape_full: 'Landscape-Full',
    landscape_80_20: 'Landscape-80/20',
    landscape_split: 'Landscape-Split',
    portrait_full: 'Portrait-Full',
    portrait_80_20: 'Portrait-80/20',
    portrait_split: 'Portrait-Split',
  };

  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [screens, setScreens] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState({
    city: '',
  });
  const [privilege, setPrivilege] = useState('');
  const [organizations, setOrganizations] = useState([]);

  const [currentScreens, setCurrentScreens] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [screensPerPage] = useState(20);
  const [skip, setSkip] = useState(0);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const navigation = [];

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('profile'));
    if (roles) {
      setPrivilege(roles.privilege);
    }

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/organization/findAll`)
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    if (e.target.type === 'number') {
      setAllScreens((prevDetails) => {
        return { ...prevDetails, [e.target.name]: parseInt(e.target.value) };
      });
    } else {
      setAllScreens((prevDetails) => {
        return { ...prevDetails, [e.target.name]: e.target.value };
      });
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setRefresh(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        setTimeout(() => setRefresh(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setRefresh(false), 2000);
        // (err);
      });

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens?limit=${screensPerPage}&skip=${skip}`,
        {
          headers,
        },
      )
      .then((res) => {
        setCurrentScreens(res.data);
        setTimeout(() => setRefresh(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setRefresh(false), 2000);
        // (err);
      });
  }, [token, screensPerPage, skip]);

  const refreshScreens = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setRefresh(true);
    // setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        setTimeout(() => {
          setRefresh(false);
          // setLoading(false);
        }, 3000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => {
          setRefresh(false);
          // setLoading(false);
        }, 3000);
        // (err);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 8000);
      });
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screen-city/all-cities`, {
        headers,
      })
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {});
  }, [token]);

  const addScreen = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setSubmitLoading(true);
    if (
      allScreens.name === '' ||
      allScreens.height === '' ||
      allScreens.width === '' ||
      allScreens.deviceId === '' ||
      allScreens.city === '' ||
      allScreens.longitude === '' ||
      allScreens.latitude === '' ||
      allScreens.layout === '' ||
      allScreens.screenId === '' ||
      allScreens.organizationId === ''
    ) {
      setMessage({
        type: 'error',
        msg: 'Please fill all the fields',
      });
      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 8000);
      setSubmitLoading(false);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens/create`,
          allScreens,
          { headers },
        )
        .then((res) => {
          setSubmitLoading(false);
          setMessage({
            type: 'success',
            msg: 'Screen created successfully!',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
            window.location.reload();
          }, 3000);

          setAllScreens({
            screenId: '',
            name: '',
            deviceId: '',
            city: '',
            longitude: '',
            latitude: '',
            layout: '',
          });
        })
        .catch((err) => {
          setSubmitLoading(false);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 8000);
        });
    }
  };

  useEffect(() => {
    if (city.city.length > 0) {
      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/ad-screen-city`, city, {
          headers,
        })
        .then((res) => {
          setAllScreens((prev) => {
            return {
              ...prev,
              longitude: res.data.longitude,
              latitude: res.data.latitude,
            };
          });
        })
        .catch((err) => {});
    }
  }, [city, token]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  const handleNavigate = (e, index) => {
    e.preventDefault();

    setSkip(screensPerPage * index - screensPerPage);
    setActivePage(index);
  };

  for (let i = 1; i <= Math.ceil(screens.length / screensPerPage); i++) {
    if (
      i <= 5 ||
      i === Math.ceil(screens.length / screensPerPage) ||
      Math.abs(activePage - i) <= 1
    ) {
      navigation.push(i);
    }
  }

  const allCities = useMemo(
    () =>
      cities.map((city, i) => ({
        id: city + i,
        value: `${city}`,
        ...city,
      })),
    [cities],
  );

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {submitLoading && <Spinner />}

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      <div className="main-content">
        <div className="content">
          <div className="allscreens">
            <div className="first-title">all screens</div>

            <div className="home-container">
              {/* ALL SCREENS BOX 1 */}
              {/* ALL SCREENS BOX 1 */}

              <div className="home-contained">
                <div className="box1-title">
                  {' '}
                  Create Screen
                  <div className="info">
                    <span>?</span>
                    <div>
                      <h3>How to link the mobile application to screen</h3>
                      <ol>
                        <li>Download the apk</li>
                        <li>Install the apk on the device</li>
                        <li>Copy device Id generated on the device</li>
                        <li>
                          Use device Id generated to create new screen details
                          on cj tronics by folham's application
                        </li>
                        <li>
                          Proceed to create campaign with the newly created
                          screen details
                        </li>
                        <li>
                          Start up the device and begin to view your adverts
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="allscreens-container">
                  <form onSubmit={addScreen}>
                    {privilege === 'superAdmin' && (
                      <select
                        name="organizationId"
                        onChange={handleInputChange}
                      >
                        <option value="">Select organization</option>
                        {organizations ? (
                          organizations.map((org, i) => (
                            <option key={i} value={org._id}>
                              {org.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No Organization </option>
                        )}
                      </select>
                    )}

                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="screenId"
                      value={allScreens.screenId}
                      placeholder="Screen ID e.g FLH2"
                    />
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="name"
                      value={allScreens.name}
                      placeholder="Screen Name"
                    />
                    <input
                      onChange={handleInputChange}
                      type="number"
                      name="height"
                      value={allScreens.height}
                      placeholder="Height in px"
                    />
                    <input
                      onChange={handleInputChange}
                      type="number"
                      name="width"
                      value={allScreens.width}
                      placeholder="Width in px"
                    />
                    <DatalistInput
                      placeholder="Select location"
                      name="city"
                      onSelect={(city) => {
                        setAllScreens((prev) => {
                          return { ...prev, city: city.value };
                        });
                        setCity(() => {
                          return { city: city.value };
                        });
                      }}
                      items={allCities}
                    />
                    {/* <select
                      onChange={handleInputChange}
                      name="city"
                      id="city"
                    >
                      <option value="">Select city</option>
                      {cities.map((city) => (
                        <option value={city}>
                          {city}
                        </option>
                      ))}
                    </select> */}
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="longitude"
                      readOnly={true}
                      value={allScreens.longitude}
                      placeholder="Longitude"
                    />
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="latitude"
                      readOnly={true}
                      value={allScreens.latitude}
                      placeholder="Latitude"
                    />
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="deviceId"
                      value={allScreens.deviceId}
                      placeholder="Unique Device ID"
                    />

                    <h2>Choose Screen Layout</h2>

                    <div className="layout-screens">
                      <div className="first-layouts">
                        <div className="landscape-full">
                          <div className="layout1">View</div>
                          <div className="landscape-title">Landscape Full</div>
                          <input
                            type="radio"
                            value={LayoutType.landscape_full}
                            name="layout"
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="landscape-full">
                          <div className="layout1">
                            <div className="layout2-1"> View 1</div>
                            <div className="layout2-2"> View 2</div>
                          </div>
                          <div className="landscape-title">Landscape 80/20</div>
                          <input
                            type="radio"
                            name="layout"
                            value={LayoutType.landscape_80_20}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="landscape-full">
                          <div className="layout3">
                            <div className="layout3-1"> View 1</div>
                            <div className="layout3-2"> View 2</div>
                          </div>
                          <div className="landscape-title">Landscape Spilt</div>
                          <input
                            type="radio"
                            name="layout"
                            value={LayoutType.landscape_split}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="first-layouts">
                        <div className="landscape-full">
                          <div className="potrait1">View</div>
                          <div className="landscape-title">Potrait Full</div>
                          <input
                            type="radio"
                            name="layout"
                            value={LayoutType.portrait_full}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="landscape-full">
                          <div className="potrait1">
                            <div className="layout2-1"> View 1</div>
                            <div className="layout2-2"> View 2</div>
                          </div>
                          <div className="landscape-title">Potrait 80/20</div>
                          <input
                            type="radio"
                            name="layout"
                            value={LayoutType.portrait_80_20}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="landscape-full">
                          <div className="potrait1">
                            <div className="potrait3-1"> View 1</div>
                            <div className="potrait3-2"> View 2</div>
                          </div>
                          <div className="landscape-title">Potrait Spilt</div>
                          <input
                            type="radio"
                            name="layout"
                            value={LayoutType.portrait_split}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <button className="addnew-btn" title="Create Ad screen">
                      {submitLoading ? <Spinner /> : 'Add Screen'}
                    </button>
                  </form>
                </div>
              </div>

              {/* ALL SCREENS BOX 2 */}
              {/* ALL SCREENS BOX 2 */}

              <div className="home-contained">
                <div className="box1-title">
                  screens ({screens.length})
                  <span>
                    <button
                      onClick={refreshScreens}
                      className="campaignschedule-button"
                      title="Refresh screens"
                    >
                      {' '}
                      <FontAwesomeIcon
                        className={refresh ? 'rotate' : ''}
                        icon={faArrowsRotate}
                      />
                    </button>
                  </span>
                </div>

                <div className="allscreens-container">
                  <p>
                    NOTE: Resolution is width x height and its unit is pixel
                    (px). This is gotten directly from the device.
                  </p>

                  <div className="allscreen-contain">
                    {!refresh ? (
                      currentScreens.map((screen, i) => (
                        <Link
                          key={i}
                          to="/screencampaign"
                          onClick={() => setSelectedScreen(screen)}
                        >
                          <div
                            key={i}
                            className={
                              screen.status === 'offline'
                                ? 'allscreen-offline'
                                : 'allscreen-online'
                            }
                          >
                            {screen.resolution && screen.resolution !== '' ? (
                              <h3>
                                {screen.screenId} - {screen.name} (
                                {screen.resolution})
                              </h3>
                            ) : (
                              <h3>
                                {screen.screenId} - {screen.name} (
                                {screen.width}x{screen.height})
                              </h3>
                            )}

                            {screen.status === 'offline' && <p>Offline</p>}
                            {/* <Link to="/screencampaign" onClick={() => setSelectedScreen(screen)}> */}
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              style={{ cursor: 'pointer' }}
                            />
                            {/* </Link> */}
                          </div>
                        </Link>
                      ))
                    ) : (
                      <h4>Please wait...</h4>
                    )}
                  </div>
                  {screens.length > 5 && (
                    <div className="page-navigation screen-navigation">
                      <button
                        type="button"
                        title="Previous"
                        onClick={() => {
                          setSkip(skip - screensPerPage);
                          setActivePage(activePage - 1);
                        }}
                        disabled={activePage === 1}
                        className={
                          activePage === 1
                            ? 'prev disabled navigation-btn'
                            : 'prev navigation-btn'
                        }
                      >
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          className="back-icon"
                        />
                      </button>
                      {navigation.map((number) => (
                        <button
                          type="button"
                          className={
                            activePage === number
                              ? 'active navigation-btn'
                              : 'navigation-btn'
                          }
                          onClick={(e) => handleNavigate(e, number)}
                        >
                          {number}
                        </button>
                      ))}
                      <button
                        type="button"
                        title="Next"
                        onClick={() => {
                          setSkip(skip + screensPerPage);
                          setActivePage(activePage + 1);
                        }}
                        disabled={
                          activePage ===
                          Math.ceil(screens.length / screensPerPage)
                        }
                        className={
                          activePage ===
                          Math.ceil(screens.length / screensPerPage)
                            ? 'next disabled navigation-btn'
                            : 'next navigation-btn'
                        }
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="back-icon"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllScreens;
