/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import SideBar from '../components/SideBar';
import ContentNavbar from '../components/ContentNavbar';
import {
  faCloudArrowDown,
  faCirclePlay,
  faPlay,
  faDisplay,
  faBook,
  faChevronDown,
  faWarning,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Spinner from '../components/Spinner';
import ToastNotification from '../components/ToastNotification';
import { Link } from 'react-router-dom';

const Home = ({ profile }) => {
  const [allScreensCount, setAllScreensCount] = useState('');
  const [campaignCount, setCampaignCount] = useState('');
  // const [totalPlayed, settotalPlayed] = useState('');

  const [campaignActivities, setCampaignActivities] = useState([]);
  const [deviceStatus, setDeviceStatus] = useState([]);

  const [activeCampaignCount, setActiveCampaignCount] = useState([]);
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setAllScreensCount(res.data.length);
        // setLoading(false)
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        // setLoading(false)
        setTimeout(() => setLoading(false), 3000);
        // if (err.message !== 'Request failed with status code 401') {  setLoading(false) }
      });

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/campaign/campaigns/active/count`,
        {
          headers,
        },
      )
      .then((res) => {
        setActiveCampaignCount(res.data[0]);
        // setLoading(false)
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        // setLoading(false)
        setTimeout(() => setLoading(false), 3000);
        // if (err.message !== 'Request failed with status code 401') {  setLoading(false) }
      });

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/campaign/campaigns-count`, {
        headers,
      })
      .then((res) => {
        setCampaignCount(res.data);
        // setLoading(false)
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        // setLoading(false)
        setTimeout(() => setLoading(false), 3000);
        // if (err.message !== 'Request failed with status code 401') {  setLoading(false) }
      });

    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_BASEURL}/device-logs/campaign-played/count`,
    //     {
    //       headers,
    //     },
    //   )
    //   .then((res) => {
    //     settotalPlayed(res.data);
    //     // setLoading(false)
    //     setTimeout(() => setLoading(false), 3000);
    //   })
    //   .catch((err) => {
    //     // setLoading(false)
    //     setTimeout(() => setLoading(false), 3000);
    //     // if (err.message !== 'Request failed with status code 401') {  setLoading(false) }
    //   });

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/campaign-activity/activities`,
        {
          headers,
        },
      )
      .then((res) => {
        setCampaignActivities(res.data);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 3000);
      });

    const loadScreens = () => {
      axios
        .get(`${process.env.REACT_APP_API_BASEURL}/device-logs/live-screen`, {
          headers,
        })
        .then((res) => {
          setDeviceStatus(res.data);
          setTimeout(() => setLoading(false), 3000);
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 3000);
        });
    };

    loadScreens();
    const interval = setInterval(async () => {
      loadScreens();
    }, 120000);
    return () => clearInterval(interval);
  }, [token]);

  const showMessage = () => {
    setMessage({
      type: 'success',
      msg: 'Feature not available yet.',
    });

    setTimeout(() => {
      setMessage({
        type: '',
        msg: '',
      });
    }, 5000);
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div className="dashboard">
      <SideBar />

      <ContentNavbar />

      {loading && <Spinner />}

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          color="rgb(241, 192, 77)"
          icon={message.type === 'success' ? faInfoCircle : faWarning}
        />
      )}

      <div className="main-content">
        <div className="content">
          <div className="homee">
            <div className="home-firsttitle">
              <div className="first-title">DashBoard</div>
              <button onClick={showMessage}>
                <Link to="/devicelog">
                  {' '}
                  <FontAwesomeIcon
                    icon={faCloudArrowDown}
                    style={{ marginRight: '10px', fontSize: '16px' }}
                    className="disappr"
                  />
                  Generate Report{' '}
                </Link>
              </button>
            </div>

            {/* CARDS SECTION */}
            {/* CARDS SECTION */}

            <div className="home-cards">
              <div
                className="home-card1"
                style={{ color: '#23C48B', borderLeft: '10px solid #23C48B' }}
              >
                <h3>online screens</h3>
                <div className="card1-mini">
                  <h2>
                    {
                      deviceStatus.filter(
                        (screen) => screen.status === 'online',
                      ).length
                    }
                  </h2>
                  <FontAwesomeIcon icon={faPlay} className="card-icon" />
                </div>
              </div>

              <div
                className="home-card1"
                style={{ color: '#4CB6CC', borderLeft: '10px solid #4CB6CC' }}
              >
                <h3>total screens</h3>
                <div className="card1-mini">
                  <h2> {deviceStatus.length} </h2>
                  <FontAwesomeIcon icon={faDisplay} className="card-icon" />
                </div>
              </div>

              <div
                className="home-card1"
                style={{ color: '#0651DF', borderLeft: '10px solid #0651DF' }}
              >
                <h3>active campaigns</h3>
                <div className="card1-mini">
                  <h2>{activeCampaignCount ? activeCampaignCount.count : 0}</h2>
                  <FontAwesomeIcon icon={faCirclePlay} className="card-icon" />
                </div>
              </div>

              <div
                className="home-card1"
                style={{ color: '#F1C04d', borderLeft: '10px solid #F1C04d' }}
              >
                <h3>total campaigns</h3>
                <div className="card1-mini">
                  <h2>{campaignCount}</h2>
                  <FontAwesomeIcon icon={faBook} className="card-icon" />
                </div>
              </div>
            </div>

            <div className="home-container">
              {/* CAMPAIGN HISTORY */}
              {/* CAMPAIGN HISTORY */}

              <div className="home-contained">
                <div className="box1-title">
                  Screens
                  <span>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                </div>

                <div className="home1-container">
                  {deviceStatus.length === 0 ? (
                    <>
                      <div className="kjapa">
                        <div className="hjuh">Loading... </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {deviceStatus.map((device, i) => (
                        <div className="kjapa" key={i}>
                          <div className="hjuh">
                            {device.screenName} - ({device.screenId})
                          </div>
                          <div
                            className={`status-badge ${
                              device.status === 'online' ? 'online-status' : ''
                            } ${
                              device.status === 'offline'
                                ? 'offline-status'
                                : ''
                            } ${
                              device.status === 'pending'
                                ? 'pending-status'
                                : ''
                            }`}
                            style={{ textAlign: 'end' }}
                          >
                            {device.status}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              {/* CAMPAIGN ACTIVITIES */}
              {/* CAMPAIGN ACTIVITIES */}

              <div className="home-contained">
                <div className="box1-title">
                  campaign activities
                  {/* <span>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span> */}
                </div>

                <div className="home1-container">
                  {campaignActivities.length === 0 ? (
                    <div className="kjapa">
                      <div className="hjuh">No campaign activity </div>
                      <div style={{ textAlign: 'end' }}>
                        {' '}
                        {new Date().getFullYear()}-
                        {new Date().getUTCMonth() + 1}-{new Date().getDate()}{' '}
                      </div>
                    </div>
                  ) : (
                    <>
                      {campaignActivities.map((actv, i) => (
                        <div className="kjapa" key={i}>
                          <div className="hjuh">{actv.message}</div>
                          <div style={{ textAlign: 'end' }}>
                            {actv.createdAt.substr(0, 10)},
                            {actv.createdAt.substr(11, 5)}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
