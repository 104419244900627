import React from 'react'
import ContentNavbar from '../components/ContentNavbar'
import SideBar from '../components/SideBar'

const GroupScreen = () => {
  return (
    <div>
      
    <SideBar />

<ContentNavbar />

    <div className="main-content">
      <div className="content">
 
      <div className='first-title'>
              Group Screen
          </div>
          
        
      </div>
    </div>
  </div>
  )
}

export default GroupScreen