/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import {
  faAngleDown,
  faCheckCircle,
  faTrashCan,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

const AdAccount = ({ selectedCampaign, setSelectedCampaign }) => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));

  const [adAccountName, setAdAccountName] = useState({
    name: '',
    adCompany: '',
    adScreen: '',
    organizationId: '',
  });

  const [adAccountCompany, setAdAccountCompany] = useState([]);

  const [companyName, setCompanyName] = useState([]);
  const [companyAdAccount, setCompanyAdAccount] = useState([]);
  const [accordionIndex, setAccordionIndex] = useState(null);
  const [adScreens, setAdScreens] = useState([]);
  const [adDeleted, setAdDeleted] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [privilege, setPrivilege] = useState('');
  const [organizations, setOrganizations] = useState([]);

  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const [loading, setLoading] = useState(false);

  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('profile'));
    if (roles) {
      setPrivilege(roles.privilege);
    }

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/organization/findAll`)
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    setAdAccountName((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-company/true`, { headers })
      .then((res) => {
        setCompanyName(res.data);
        setTimeout(() => setLoading(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setAdScreens(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, { headers })
      .then((res) => {
        setAdAccountCompany(res.data);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 3000);
      });
  }, [token, submit]);

  const [screenLoading, setScreenLoading] = useState(false);
  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    if (adAccountName.adCompany !== '') {
      setScreenLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/get-ad-company/${adAccountName.adCompany}`,
          { headers },
        )
        .then((res) => {
          setCompanyAdAccount(res.data);
          setTimeout(() => setScreenLoading(false), 3000);
        })
        .catch((err) => {
          setTimeout(() => setScreenLoading(false), 3000);
        });
    }
  }, [token, adAccountName.adCompany, adDeleted]);

  const [accountCreated, setAccountCreated] = useState(false);
  const adAccount = (e) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    e.preventDefault();

    if (
      adAccountName.name === '' ||
      adAccountName.adCompany === '' ||
      adAccountName.adScreen === ''
    ) {
      setMessage({
        type: 'error',
        msg: 'Please fill all fields',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
    } else {
      setLoading(true);
      setSubmit(true);

      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}/ad-accounts/create`,
          adAccountName,
          { headers },
        )
        .then((res) => {
          setLoading(false);
          setSubmit(false);
          // alert(res.statusText);
          document.querySelectorAll('select').forEach(function (el) {
            el.value = '';
          });
          setAdAccountName({
            name: '',
            adCompany: '',
            adScreen: '',
          });
          setAccountCreated(true);
          setMessage({
            type: 'success',
            msg: res.data.message,
          });

          setTimeout(() => {
            setAccountCreated(false);
            setMessage({
              type: '',
              msg: '',
            });
          }, 10000);
        })
        .catch((err) => {
          setLoading(false);
          setSubmit(false);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 8000);
        });
    }
  };

  const deleteAdAccount = (id) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setLoading(true);
    setAdDeleted(false);

    axios
      .delete(`${process.env.REACT_APP_API_BASEURL}/ad-accounts/${id}/delete`, {
        headers,
      })
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(true);
        setAdAccountName({
          name: '',
          adCompany: '',
          adScreen: '',
        });
        setMessage({
          type: 'success',
          msg: res.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(false);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 8000);
      });
  };

  const deleteCampaign = (id) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // let adId = screenCampaigns.filter((a) => a._id === ad._id);

    setLoading(true);
    setAdDeleted(false);
    // (adId);

    axios
      .delete(
        `${process.env.REACT_APP_API_BASEURL}/campaign/campaigns/${id}/delete`,
        {
          headers,
        },
      )
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(true);
        if (res.data.error) {
          setMessage({
            type: 'error',
            msg: res.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 5000);
          return;
        }
        setMessage({
          type: 'success',
          msg: res.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(false);
      });
  };

  const handleAccordion = (index) => {
    if (accordionIndex === index) {
      return setAccordionIndex(null);
    }
    setAccordionIndex(index);
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && !accountCreated && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {message.msg !== '' && accountCreated && (
        <ToastNotification
          type={message.type}
          text={
            message.type === 'error'
              ? message.msg
              : 'Ad account created. To add ads to the account, go to '
          }
          url={message.type === 'error' ? '' : '/createcampaign'}
          urlText="create campaign."
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Ad Account</div>

          <div className="adaccount-container">
            {/* CREATE AD ACCOUNT BOX */}
            {/* CREATE AD ACCOUNT BOX */}

            <div className="adaccount-box1">
              <div className="box1-title">create ad account</div>
              <div className="box1-container">
                <div
                  className="box1-container-content"
                  style={{ paddingTop: '1.5rem' }}
                >
                  <form onSubmit={adAccount}>
                    {privilege === 'superAdmin' && (
                      <select
                        name="organizationId"
                        onChange={handleInputChange}
                      >
                        <option value="">Select organization</option>
                        {organizations ? (
                          organizations.map((org, i) => (
                            <option key={i} value={org._id}>
                              {org.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No Organization </option>
                        )}
                      </select>
                    )}
                    <select
                      name="adCompany"
                      id="ad-company"
                      onChange={handleInputChange}
                      style={{ marginTop: '1rem' }}
                    >
                      <option value="">Select Company Name</option>
                      {companyName.map((company, i) => {
                        return (
                          <option key={i} value={company._id}>
                            {company.companyName}
                          </option>
                        );
                      })}
                    </select>

                    <select
                      name="adScreen"
                      id="ad-screen"
                      onChange={handleInputChange}
                      style={{ marginTop: '1rem' }}
                    >
                      <option value="">Select Screen</option>
                      {adScreens.map((screen, i) => {
                        return (
                          <option key={screen._id} value={screen._id}>
                            {screen.name}
                          </option>
                        );
                      })}
                    </select>

                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="name"
                      name="name"
                      value={adAccountName.name}
                      placeholder="Ad Account Name"
                      // required
                    />

                    <button title="Create Ad Account" className="addnew-btn">
                      {loading && submit ? <Spinner /> : 'Create Ad Account'}
                    </button>
                  </form>
                </div>
              </div>
            </div>

            {/* AD ACCOUNT BOX */}
            {/* AD ACCOUNT BOX */}

            <div className="adaccount-box1">
              <div className="box1-title">
                ad account ({companyAdAccount.adAccountAndCampaigns?.length})
              </div>

              <div className="box2-container">
                <div className="box2-container-content">
                  {!screenLoading &&
                    adAccountName.adCompany !== '' &&
                    companyAdAccount.adAccountAndCampaigns?.map((ad, i) => (
                      <div
                        key={i}
                        className="accordion"
                        onClick={() => handleAccordion(i)}
                      >
                        <div
                          className={
                            accordionIndex === i && ad.campaigns.length !== 0
                              ? 'accordion-heading heading-active'
                              : 'accordion-heading'
                          }
                        >
                          <p style={{ flex: '1' }}>{ad.adAccount}</p>
                          <div>
                            <p>
                              {ad.campaigns.length}{' '}
                              {ad.campaigns.length < 2
                                ? 'Campaign'
                                : 'Campaigns'}
                            </p>
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              style={{
                                transform:
                                  accordionIndex === i &&
                                  ad.campaigns.length !== 0
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                              }}
                            />
                            <span
                              onClick={() => deleteAdAccount(ad?.adAccountId)}
                              title={`Delete ${ad.adAccount}`}
                              className="ico"
                              style={{ color: '#E74A3B', cursor: 'pointer' }}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </span>
                          </div>
                        </div>
                        <ul
                          className={
                            accordionIndex === i && ad.campaigns.length !== 0
                              ? 'accordion-content active'
                              : 'accordion-content'
                          }
                        >
                          {ad.campaigns.map((campaign, i) => {
                            return (
                              <li>
                                <Link
                                  key={i}
                                  to={`/view_ad_account/${campaign.name.replace(
                                    /\s+/g,
                                    '-',
                                  )}`}
                                  onClick={() =>
                                    setSelectedCampaign(campaign._id)
                                  }
                                >
                                  {campaign.name}
                                </Link>
                                <span
                                  onClick={() => {
                                    deleteCampaign(campaign?._id);
                                  }}
                                  title={`Delete ${campaign.name}`}
                                  className="ico float-end"
                                  style={{
                                    color: '#E74A3B',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ))}
                  {!screenLoading && adAccountName.adCompany === '' && (
                    <h4>Select Company to see Ad Accounts</h4>
                  )}
                  {!screenLoading &&
                  companyAdAccount.adAccountAndCampaigns?.length === 0 &&
                  adAccountName.adCompany !== '' ? (
                    <h4>No Ad Accounts available</h4>
                  ) : (
                    ''
                  )}
                  {!screenLoading && adAccountCompany.length === 0 && (
                    <div>No Ad Account</div>
                  )}
                  {screenLoading && <h3>Loading...</h3>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdAccount;
