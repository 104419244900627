/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
// import nature from "../img/nature.jpg";
import axios from 'axios';
import Spinner from '../components/Spinner';
import ToastNotification from '../components/ToastNotification';
import {
  faCheckCircle,
  faTrashCan,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditCampaign = ({ editableCampaign, setEditableCampaign }) => {
  const [editCampaign, setEditCampaign] = useState({
    // adScreen: "",
    adAccount: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    adFiles: [],
    campaignScheduleDays: [],
    videoAndImageDuration: 0,
    campaign: '',
  });
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [show1, setShow1] = useState(false);
  // const [screens, setScreens] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [campaignId, setCampaignId] = useState('');
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [adAccountAds, setAdAccountAds] = useState([]);
  const [ads, setAds] = useState([]);
  const [adDeleted, setAdDeleted] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const navigate = useNavigate();

  // const data = {
  //   campaign: editCampaign.campaign,
  //   adAccount: editCampaign.adAccount,
  //   adScreen: editCampaign.adScreen,
  //   startDate: editCampaign.startDate,
  //   endDate: editCampaign.endDate,
  //   startTime: editCampaign.startTime,
  //   endTime: editCampaign.endTime,
  //   duration: editCampaign.duration,
  //   campaignScheduleDays: editCampaign.campaignScheduleDays,
  // };

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  const handleInputChange = (e) => {
    if (e.target.type === 'range') {
      setEditCampaign((prevDetails) => {
        return { ...prevDetails, [e.target.name]: parseInt(e.target.value) };
      });
    } else {
      setEditCampaign((prevDetails) => {
        return { ...prevDetails, [e.target.name]: e.target.value };
      });
    }
  };

  const handleAdFileSelect = (e) => {
    if (editCampaign.adFiles.includes(e.target.value)) {
      const filterAds = editCampaign.adFiles.filter(
        (file) => file !== e.target.value,
      );
      setEditCampaign((prevDetails) => {
        return { ...prevDetails, adFiles: filterAds };
      });
    } else {
      setEditCampaign((prevDetails) => {
        return {
          ...prevDetails,
          adFiles: [...editCampaign.adFiles, e.target.value],
        };
      });
    }
  };

  const handleCampaignChange = (e) => {
    if (editCampaign.campaignScheduleDays.includes(e.target.value)) {
      const filterCampaign = editCampaign.campaignScheduleDays.filter(
        (day) => day !== e.target.value,
      );
      setEditCampaign((prevDetails) => {
        return { ...prevDetails, campaignScheduleDays: filterCampaign };
      });
    } else {
      setEditCampaign((prevDetails) => {
        return {
          ...prevDetails,
          campaignScheduleDays: [
            ...editCampaign.campaignScheduleDays,
            e.target.value,
          ],
        };
      });
    }
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ads/get/all-ads`, {
        headers,
      })
      .then((res) => {
        setAds(res.data);
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/campaign/campaigns`, {
        headers,
      })
      .then((res) => {
        setCampaign(res.data);
        setTimeout(() => setLoading(false), 2000);
        res.data[0].startDate.toIsoString();
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  useEffect(() => {
    campaign.forEach((campaign) => {
      if (campaign._id === campaignId) {
        setEditableCampaign(campaign);
      }
    });
  }, [campaign, editCampaign, campaignId, setEditableCampaign, loading]);
  useEffect(() => {
    // campaign.map((campaign) => {
    if (Object.keys(editableCampaign).length > 0) {
      setEditCampaign({
        // adScreen: editableCampaign && editableCampaign.adScreen,
        adAccount: editableCampaign && editableCampaign.adAccount,
        startDate:
          editableCampaign && editableCampaign.startDate?.substr(0, 10),
        endDate: editableCampaign && editableCampaign.endDate?.substr(0, 10),
        startTime: editableCampaign && editableCampaign.startTime,
        endTime: editableCampaign && editableCampaign.endTime,
        campaignScheduleDays:
          editableCampaign && editableCampaign.campaignScheduleDays,
        videoAndImageDuration:
          editableCampaign && editableCampaign.videoAndImageDuration,
        campaign: editableCampaign && editableCampaign._id,
        adFiles: editableCampaign && editableCampaign.adFiles,
      });
    } else {
      setEditCampaign({
        // adScreen: "",
        adAccount: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        campaignScheduleDays: [],
        videoAndImageDuration: 0,
        // duration: 0,
        campaign: '',
        adFiles: [],
      });
    }
    // (campaign.startDate?.substr(0,10));
    //
  }, [editableCampaign, campaign, token, loading]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, { headers })
      .then((res) => {
        setAdAccounts(res.data);
        setTimeout(() => setLoading(false), 2000);
        // (res.data);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  const handleSubmit = (e, id) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setSubmitLoading(true);

    if (
      editCampaign.campaignScheduleDays.length === 0 ||
      editCampaign.adAccount === '' ||
      editCampaign.startDate === '' ||
      editCampaign.endDate === '' ||
      editCampaign.startTime === '' ||
      editCampaign.endTime === ''
    ) {
      setMessage({
        type: 'error',
        msg: 'Please fill all the fields',
      });
      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 6000);
      setSubmitLoading(false);
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_BASEURL}/campaign/campaigns/${id}/update`,
          editCampaign,
          { headers },
        )
        .then((res) => {
          setSubmitLoading(false);
          setMessage({
            type: 'success',
            msg: 'Campaign updated successfully!',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });

            setEditCampaign({
              // adScreen: "",
              adAccount: '',
              startDate: '',
              endDate: '',
              startTime: '',
              endTime: '',
              campaignScheduleDays: [],
              videoAndImageDuration: 0,
              // duration: 0,
              campaign: '',
              adFiles: [],
            });
            navigate('/campaignschedule');

            window.location.reload();
          }, 3000);
          setCampaignId('');
          // setEditableCampaign({});
          document
            .querySelectorAll('input[type=checkbox]')
            .forEach(function (el) {
              el.checked = false;
            });
          document.querySelectorAll('select').forEach(function (el) {
            el.value = '';
          });
          setEditCampaign({
            // adScreen: "",
            adAccount: '',
            name: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            campaignScheduleDays: [],
            videoAndImageDuration: 0,
            // duration: 0,
          });
        })
        .catch((err) => {
          setSubmitLoading(false);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 8000);
          // (err);
        });
    }
  };

  const deleteFile = (id) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setLoading(true);
    setAdDeleted(false);

    axios
      .delete(`${process.env.REACT_APP_API_BASEURL}/ads/${id}/delete`, {
        headers,
      })
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(true);
        setMessage({
          type: 'success',
          msg: res.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setAdDeleted(false);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
      });
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  useEffect(() => {
    let filteredAds = ads.filter(
      (ad) => ad.adAccount === editCampaign.adAccount,
    );
    setAdAccountAds(filteredAds);
  }, [ads, editCampaign.adAccount, editCampaign.campaign]);

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {(submitLoading || loading) && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="editcampaign">
            <div className="first-title">Edit campaign</div>

            <form
              onSubmit={(e) =>
                handleSubmit(e, campaignId || editableCampaign._id)
              }
            >
              <div className="editcampaign-firstsection">
                <label>Select Campaign to edit</label>
                <select
                  name="campaign"
                  onChange={(e) => setCampaignId(e.target.value)}
                >
                  <option>Select Campaign</option>
                  {campaign.map((camp, i) => {
                    return (
                      <option
                        selected={camp._id === editableCampaign?._id}
                        key={i}
                        value={camp._id}
                      >
                        {camp.name}
                      </option>
                    );
                  })}
                </select>

                {/* <label>Screen ID/Name </label>
                <select name="adScreen" onChange={handleInputChange}>
                  <option>Screen ID/Name</option>
                  {screens.map((screen, i) => {
                    return (
                      <option selected={screen._id === editableCampaign?.adScreen} key={i} value={screen._id}>
                        {screen.screenId} - {screen.name}
                      </option>
                    );
                  })}
                </select> */}

                <label>Select Ad Account </label>
                <select name="adAccount" onChange={handleInputChange}>
                  <option>Select Ad Account</option>
                  {adAccounts.map((account, i) => {
                    return (
                      <option
                        selected={account._id === editableCampaign?.adAccount}
                        key={i}
                        value={account._id}
                      >
                        {account.adAccountName}
                      </option>
                    );
                  })}
                </select>
                {adAccountAds.length > 0 && (
                  <div>
                    <label>Select Ads</label>
                    <div className="ad-account-ads">
                      {adAccountAds.map((ad, i) => (
                        <div key={i} className="ad">
                          <input
                            type="checkbox"
                            name="adFile"
                            id={ad._id}
                            style={{ width: '20px', height: '20px' }}
                            value={ad._id}
                            defaultChecked={editableCampaign.adFiles.includes(
                              ad._id,
                            )}
                            title="Select Ad"
                            onChange={handleAdFileSelect}
                          />
                          {ad.adFile.map((file, i) => {
                            return (
                              <div className="ad-thumbnail" key={i}>
                                {file.adType === 'picture' &&
                                  !file.adUrl.includes('mp4') && (
                                    <img src={file.adUrl} alt={file.adName} />
                                  )}
                                {(file.adType === 'video' ||
                                  file.adUrl.includes('mp4')) && (
                                  <video
                                    src={file.adUrl}
                                    controls
                                    alt={file.adName}
                                  />
                                )}
                                {file.adUrl.includes('youtube.com') && (
                                  <iframe
                                    src={file.adUrl}
                                    title={file.adName}
                                    style={{ width: '100%' }}
                                    frameBorder="0"
                                  ></iframe>
                                )}
                                <div
                                  style={{
                                    fontSize: '13px',
                                    textAlign: 'left',
                                  }}
                                  className="label"
                                >
                                  <label
                                    style={{
                                      fontSize: '13px',
                                      textAlign: 'left',
                                      wordBreak: 'break-word',
                                      padding: 0,
                                    }}
                                    htmlFor={file.adName}
                                  >
                                    {file.adName} - {file.adUrl}
                                  </label>
                                  <span
                                    onClick={() => deleteFile(ad?._id)}
                                    title={`Delete ${file.adName}`}
                                    className="ico"
                                    style={{
                                      color: '#E74A3B',
                                      cursor: 'pointer',
                                      display: 'block',
                                      marginTop: '10px',
                                      textAlign: 'right',
                                      fontSize: '20px',
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashCan} />
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="editcampaign-secondsection">
                {/* <div className="picturedemo">
                  <img src={nature} alt="" />
                  <p>Ramadan30 (Ended)</p>
                </div> */}

                <div className="mini-formm">
                  <div className="editcampaign-form">
                    <label htmlFor="date">Start Date </label>
                    <input
                      type="date"
                      name="startDate"
                      // defaultValue={editableCampaign.startDate}
                      value={editCampaign.startDate}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="editcampaign-form">
                    <label htmlFor="date">End Date </label>
                    <input
                      type="date"
                      name="endDate"
                      // defaultValue={editableCampaign.endDate}
                      value={editCampaign.endDate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="mini-formm">
                  <div className="editcampaign-form">
                    <label htmlFor="time">Start Playtime </label>
                    <input
                      type="time"
                      name="startTime"
                      // defaultValue={campaign[0]?.startTime}
                      value={editCampaign.startTime}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="editcampaign-form">
                    <label htmlFor="time">End Playtime </label>
                    <input
                      type="time"
                      name="endTime"
                      // defaultValue={campaign[0]?.endTime}
                      value={editCampaign.endTime}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="mini-formm">
                  <div className="editcampaign-form">
                    <label htmlFor="days">Select Days </label>

                    <div className="editc-form">
                      <div className="edict-fsb">
                        <label htmlFor="day">Sun</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Sunday',
                          )}
                          value="Sunday"
                          onChange={handleCampaignChange}
                        />
                      </div>

                      <div className="edict-fsb">
                        <label htmlFor="vehicle1">Mon</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Monday',
                          )}
                          value="Monday"
                          onChange={handleCampaignChange}
                        />
                      </div>

                      <div className="edict-fsb">
                        <label htmlFor="vehicle1">Tue</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Tuesday',
                          )}
                          value="Tuesday"
                          onChange={handleCampaignChange}
                        />
                      </div>

                      <div className="edict-fsb">
                        <label htmlFor="vehicle1">Wed</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Wednesday',
                          )}
                          value="Wednesday"
                          onChange={handleCampaignChange}
                        />
                      </div>

                      <div className="edict-fsb">
                        <label htmlFor="vehicle1">Thur</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Thursday',
                          )}
                          value="Thursday"
                          onChange={handleCampaignChange}
                        />
                      </div>

                      <div className="edict-fsb">
                        <label htmlFor="vehicle1">Fri</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Friday',
                          )}
                          value="Friday"
                          onChange={handleCampaignChange}
                        />
                      </div>

                      <div className="edict-fsb">
                        <label htmlFor="vehicle1">Sat</label>
                        <input
                          type="checkbox"
                          name="campaignScheduleDays"
                          defaultChecked={editableCampaign.campaignScheduleDays?.includes(
                            'Saturday',
                          )}
                          value="Saturday"
                          onChange={handleCampaignChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="efrf">
                    <div className="mini-range">
                      <label
                        htmlFor="videoAndImageDuration"
                        style={{ fontSize: '1.2rem' }}
                      >
                        Video and Image duration (59secs)
                      </label>
                      <div className="show-value">
                        {show1 && (
                          <span
                            id="current-value"
                            style={{
                              left: `${
                                editCampaign.videoAndImageDuration * 1.65
                              }%`,
                            }}
                          >
                            {editCampaign.videoAndImageDuration}
                          </span>
                        )}
                      </div>
                      <input
                        type="range"
                        name="videoAndImageDuration"
                        id="custom-slider"
                        min="0"
                        max="59"
                        onChange={handleInputChange}
                        onMouseLeave={() => setShow1(false)}
                        onMouseOver={() => setShow1(true)}
                        // defaultValue={editableCampaign.duration}
                        value={editCampaign.videoAndImageDuration}
                      />
                    </div>
                    {/* <div className="mini-range">
                      <label htmlFor="duration" style={{ fontSize: "1.2rem" }}>
                        Image duration (60secs)
                      </label>
                      <div className="show-value">
                        {show2 && (
                          <span
                            id="current-value"
                            style={{ left: `${editCampaign.imageDuration * 1.65}%` }}
                          >
                            {editCampaign.imageDuration}
                          </span>
                        )}
                      </div>
                      <input
                        type="range"
                        name="imageDuration"
                        id="custom-slider"
                        min="0"
                        max="60"
                        onChange={handleInputChange}
                        onMouseLeave={() => setShow2(false)}
                        onMouseOver={() => setShow2(true)}
                        // defaultValue={editableCampaign.duration}
                        value={editCampaign.imageDuration}
                      />
                    </div> */}
                  </div>
                </div>

                <button className="addnew-btn" title="Update campaign">
                  Update Campaign
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCampaign;
