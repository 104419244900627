import { NavLink, useNavigate } from 'react-router-dom';
import {
  faArrowCircleLeft,
  faBars,
  faBuilding,
  faChartLine,
  faCheckCircle,
  faCirclePlay,
  faDisplay,
  faFireFlameCurved,
  faGaugeHigh,
  faGear,
  faPlay,
  faUser,
  faWarning,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import SidebarMenu from './SidebarMenu';
import Spinner from './Spinner';
import axios from 'axios';
import ToastNotification from './ToastNotification';

const routes = [
  {
    path: '/addnewad',
    name: 'Add Ad Company',
    icon: <FontAwesomeIcon icon={faBuilding} />,
  },
  {
    path: '/adaccount',
    name: 'Ad Account',
    icon: <FontAwesomeIcon icon={faPlay} />,
    title: 'Ad Account',
  },
  {
    path: '/adduser',
    name: 'Users',
    icon: <FontAwesomeIcon icon={faUser} />,
    // title: "All Users",
    exact: true,
    subRoutes: [
      {
        path: '/ad-account-companies',
        name: 'Ad Companies ',
        title: 'Ad Account Companies',
      },
    ],
  },
  {
    path: '',
    name: 'Screens',
    icon: <FontAwesomeIcon icon={faDisplay} />,
    title: 'Screens',
    subRoutes: [
      {
        path: '/allscreens',
        name: 'All Screens ',
      },
      {
        path: '/screencampaign',
        name: 'Screen Campaign',
      },
      // {
      //   path: "/groupscreen",
      //   name: "Group Screen",
      // },
    ],
  },
  {
    path: '/campaign',
    name: 'Campaign',
    icon: <FontAwesomeIcon icon={faCirclePlay} />,
    exact: true,
    subRoutes: [
      {
        path: '/ads',
        name: 'Create Ads ',
      },
      {
        path: '/createcampaign',
        name: 'Create Campaign',
      },
      {
        path: '/campaignschedule',
        name: 'Campaign Schedule',
      },
      // {
      //   path: '/editcampaign',
      //   name: 'Edit Campaign',
      // },
    ],
  },
  {
    path: '/widget',
    name: 'Widgets',
    icon: <FontAwesomeIcon icon={faGear} />,
    title: 'Widgets',
  },
  {
    path: '/activitylog',
    name: 'Activity Log',
    icon: <FontAwesomeIcon icon={faChartLine} />,
    title: 'Activity Log',
  },
  {
    path: '/devicelog',
    name: 'Device Log',
    icon: <FontAwesomeIcon icon={faFireFlameCurved} />,
  },
];

const restrictedRoutes = [
  {
    path: '/addnewad',
    name: 'Add Ad Company',
    icon: <FontAwesomeIcon icon={faBuilding} />,
  },
  {
    path: '/adduser',
    name: 'Users',
    icon: <FontAwesomeIcon icon={faUser} />,
    // title: "All Users",
    exact: true,
    subRoutes: [
      {
        path: '/adduser',
        name: 'All Users',
        title: 'All Users',
      },
      {
        path: '/ad-account-companies',
        name: 'Ad Companies ',
        title: 'Ad Account Companies',
      },
      {
        path: '/organizations',
        name: 'Organizations ',
        title: 'Organizations',
      },
    ],
  },
  {
    path: '',
    name: 'Screens',
    icon: <FontAwesomeIcon icon={faDisplay} />,
    title: 'Screens',
    subRoutes: [
      {
        path: '/allscreens',
        name: 'All Screens ',
      },
      {
        path: '/screencampaign',
        name: 'Screen Campaign',
      },
      // {
      //   path: "/groupscreen",
      //   name: "Group Screen",
      // },
    ],
  },
  {
    path: '/adaccount',
    name: 'Ad Account',
    icon: <FontAwesomeIcon icon={faPlay} />,
    title: 'Ad Account',
  },

  {
    path: '/campaign',
    name: 'Campaign',
    icon: <FontAwesomeIcon icon={faCirclePlay} />,
    exact: true,
    subRoutes: [
      {
        path: '/ads',
        name: 'Create Ads ',
      },
      {
        path: '/createcampaign',
        name: 'Create Campaign',
      },
      {
        path: '/campaignschedule',
        name: 'Campaign Schedule',
      },
      // {
      //   path: "/editcampaign",
      //   name: "Edit Campaign",
      // },
    ],
  },

  {
    path: '/widget',
    name: 'Widgets',
    icon: <FontAwesomeIcon icon={faGear} />,
    title: 'Widgets',
  },
  {
    path: '/activitylog',
    name: 'Activity Log',
    icon: <FontAwesomeIcon icon={faChartLine} />,
    title: 'Activity Log',
  },
  {
    path: '/devicelog',
    name: 'Device Log',
    icon: <FontAwesomeIcon icon={faFireFlameCurved} />,
  },
  {
    path: '/support',
    name: 'Support',
    icon: <FontAwesomeIcon icon={faWrench} />,
    title: 'Support',
  },
];

const SideBar = ({ children }) => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [profile, setProfile] = useState({});
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setToken(localStorage.getItem('accessToken'));
    }, 3000);
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/profile`, { headers })
      .then((res) => {
        // setProfile(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem('profile')) {
      setProfile(JSON.parse(localStorage.getItem('profile')));
    } else if (
      !localStorage.getItem('profile') &&
      error.message === 'Network Error'
    ) {
      setLoading(false);
      setError(error.message);
      setMessage({
        type: 'error',
        msg: `${error.message}! Please check your internet connection.`,
      });
    } else {
      // localStorage.clear();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
        setProfile(JSON.parse(localStorage.getItem('profile')));
      }, 5000);
    }
  }, [error.message]);

  const logout = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      window.location.reload();
      localStorage.clear();
      navigate('/');
    }, 2000);
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5,
      },
    },
  };

  const [screen, setScreen] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(screen > 850 ? false : true);

  useEffect(() => {
    function handleResize() {
      setScreen(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    if (screen > 850) {
      setMobile(false);
      setIsOpen(true);
    } else {
      setMobile(true);
      setIsOpen(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [screen, mobile]);

  return (
    <>
      {loading && <Spinner />}
      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}
      <div className="main-container">
        <div className="top_section"></div>

        <motion.div
          animate={{
            width: isOpen ? '200px' : '45px',

            transition: {
              duration: 0.5,
              type: 'spring',
              damping: 15,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section"> </div>

          <div className="bars" style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggle}
              className="bar-icon"
            />
          </div>
          <div className="nav-dashboard">
            <NavLink
              to="/home"
              title="Dashboard"
              style={{ display: 'flex', color: '#fff' }}
            >
              <FontAwesomeIcon icon={faGaugeHigh} className="dh-sb-icon" />
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    Dashboard
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          </div>

          <div className="interface">INTERFACE</div>

          {profile?.privilege === 'user' && (
            <section className="routes">
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      key={index}
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={false}
                      isOpen={isOpen}
                      title={route.title}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    title={route.title}
                  >
                    <div className="sb-icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={false}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
              <div
                className="link"
                onClick={logout}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faArrowCircleLeft} className="sb-icon" />
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      Logout
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </section>
          )}
          {(profile?.privilege === 'superAdmin' ||
            (profile?.privilege === 'admin' && profile.organizationId)) && (
            <section className="routes">
              {restrictedRoutes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      key={index}
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={false}
                      isOpen={isOpen}
                      title={route.title}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    title={route.title}
                  >
                    <div className="sb-icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={false}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}

              <div
                className="link"
                onClick={logout}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faArrowCircleLeft} className="sb-icon" />
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      Logout
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </section>
          )}
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
