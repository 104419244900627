import React, { useState, useEffect } from 'react';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ToastNotification from './ToastNotification';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import logo from '../img/logo.png';

const ContentNavbar = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));

  const [profile, setProfile] = useState({});

  const [prof, setProf] = useState({});

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');

  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    // setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/profile`, { headers })
      .then((res) => {
        setLoading(false);
        setProf(res.data);
        localStorage.setItem('profile', JSON.stringify(res.data));
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          setError(err?.response?.data?.message);
        }, 10000);
        if (err.message === 'Network Error') {
          setError(err.message);
          setMessage({
            type: 'error',
            msg: `${err.message}! Please check your internet connection.`,
          });
        }
      });
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      setProfile(JSON.parse(localStorage.getItem('profile')));
    }, 2000);
  }, []);

  useEffect(() => {
    if (
      !loading &&
      Object.keys(prof).length === 0 &&
      error === 'Unauthorized'
    ) {
      // alert("You are not logged in. Please login again.");
      localStorage.clear();
      // window.location.reload();
      // navigate("/");
    }
  }, [error, prof, loading, navigate]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };
  return (
    <div className="contentnav">
      <div className="logo">
        <img src={logo} alt="Folham" />
      </div>
      <div className="contentnav-content">
        {message.msg !== '' && (
          <ToastNotification
            type={message.type}
            text={message.msg}
            onclick={handleCloseNotification}
            icon={message.type === 'success' ? faCheckCircle : faWarning}
          />
        )}
        {error !== 'Network Error' && (
          <p>
            {loading ||
            (profile?.firstName === undefined &&
              profile?.lastName === undefined)
              ? 'Loading...'
              : profile?.firstName + ' ' + profile?.lastName}
          </p>
        )}
        <FontAwesomeIcon icon={faCircleUser} className="content-icon" />
      </div>
    </div>
  );
};

export default ContentNavbar;
