import {
  faBox,
  faCheckCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import Spinner from '../components/Spinner';
import ToastNotification from '../components/ToastNotification';

const AdAccountCompanies = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(false);
  const [adCompanies, setAdCompanies] = useState([]);
  const [success, setSuccess] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [companyDetails, setCompanyDetails] = useState({
    // companyName: "",
    // companyAbbreviation: "",
    // adAccountManager: "",
    // adAccountManagerEmail: "",
    // adAccountManagerPhoneNumber: "",
    companyActiveStatus: null,
  });
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-company/false`, { headers })
      .then((res) => {
        setAdCompanies(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token, success]);

  // const handleStatusChange = (company) => {
  //   setCompanyDetails({
  //     companyName: company.companyName,
  //     companyAbbreviation: company.companyAbbreviation,
  //     adAccountManager: company.adAccountManager,
  //     adAccountManagerEmail: company.adAccountManagerEmail,
  //     adAccountManagerPhoneNumber: company.adAccountManagerPhoneNumber,
  //     companyActiveStatus: company.companyActiveStatus === 0 ? 1 : 0,
  //   });

  //   handleActivate(company, companyDetails);
  // }

  // useEffect(() => {
  //   if (selectedCompany?.companyName !== "") {
  //     setCompanyDetails({
  //       // companyName: selectedCompany?.companyName,
  //       // companyAbbreviation: selectedCompany?.companyAbbreviation,
  //       // adAccountManager: selectedCompany?.adAccountManager,
  //       // adAccountManagerEmail: selectedCompany?.adAccountManagerEmail,
  //       // adAccountManagerPhoneNumber: selectedCompany?.adAccountManagerPhoneNumber,
  //       companyActiveStatus: selectedCompany?.companyActiveStatus === 0 ? 1 : 0,
  //     });
  //   }
  // }, [selectedCompany?.companyName, selectedCompany]);

  const approveCompany = (company) => {
    if (company.companyActiveStatus === 1) {
      setCompanyDetails({
        companyActiveStatus: 0,
      });
    }
  };

  const disapproveCompany = (company) => {
    if (company.companyActiveStatus === 0) {
      setCompanyDetails({
        companyActiveStatus: 1,
      });
    }
  };

  useEffect(() => {
    if (companyDetails.companyActiveStatus !== null && selectedCompany !== '') {
      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      setLoading(true);

      axios
        .put(
          `${process.env.REACT_APP_API_BASEURL}/ad-company/${selectedCompany._id}/update`,
          companyDetails,
          { headers },
        )
        .then((res) => {
          setTimeout(() => setLoading(false), 2000);
          setSuccess(true);
          setTimeout(() => {
            // window.location.reload();
            setSelectedCompany('');
            setCompanyDetails({
              companyName: '',
              companyAbbreviation: '',
              adAccountManager: '',
              adAccountManagerEmail: '',
              adAccountManagerPhoneNumber: '',
              companyActiveStatus: null,
            });
            setSuccess(false);
          }, 2000);
          setMessage({
            type: 'success',
            msg:
              selectedCompany?.companyActiveStatus === 0
                ? `Deactivated ${selectedCompany?.companyName}`
                : `Activated ${selectedCompany?.companyName}`,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 5000);
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 5000);
        });
    }
  }, [
    companyDetails.companyActiveStatus,
    token,
    companyDetails,
    selectedCompany,
  ]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Ad Account Companies</div>

          <div className="second-title">Ad Account Company settings</div>

          <div className="addnew-container">
            <div className="addnew-content">
              {adCompanies.map((company, index) => (
                <div
                  title={
                    company.companyActiveStatus === 0
                      ? 'Currently active'
                      : 'Currently not active'
                  }
                  className={
                    company.companyActiveStatus === 0
                      ? 'user-box active'
                      : 'user-box inactive'
                  }
                >
                  <div className="user-details">
                    <div className="icon-box">
                      <FontAwesomeIcon icon={faBox} className="icon" />
                    </div>
                    <div>
                      <h4>{company.companyName}</h4>
                    </div>
                    {company.privilege === 'admin' && (
                      <span className="admin-badge">Admin</span>
                    )}
                  </div>
                  <div className="btns">
                    <button
                      type="button"
                      title={
                        company.companyActiveStatus === 1 &&
                        `Activate ${company.companyName}`
                      }
                      disabled={company.companyActiveStatus === 0}
                      className={
                        company.companyActiveStatus === 1
                          ? 'user-btn'
                          : 'disabled user-btn'
                      }
                      onClick={() => {
                        approveCompany(company);
                        // handleActivate(company);
                        setSelectedCompany(company);
                      }}
                    >
                      Activate
                    </button>
                    <button
                      type="button"
                      title={
                        company.companyActiveStatus === 0 &&
                        `Deactivate ${company.companyName}`
                      }
                      disabled={company.companyActiveStatus === 1}
                      className={
                        company.companyActiveStatus === 0
                          ? 'user-btn'
                          : 'disabled user-btn'
                      }
                      onClick={() => {
                        disapproveCompany(company);
                        // handleActivate(company);
                        setSelectedCompany(company);
                      }}
                    >
                      Deactivate
                    </button>
                    {/* <button>
                      <FontAwesomeIcon icon={ faEllipsisVertical } className="icon" />
                    </button> */}
                    {/* <div className="options-container">
                      <button
                        type="button"
                        ref={btnRef}
                        title="More"
                        onClick={() => toggleOptions(user, index)}
                        className="options-btn"
                      >
                        <FontAwesomeIcon icon={ faEllipsisVertical } className="icon" />
                      </button>
                      <ul
                        className={`${
                          optionIndex === index
                            ? "options show"
                            : "options"
                        }`}
                      >
                        <li>
                          <button 
                            type="button" 
                            title={user.privilege === "admin" ? "Deactivate as admin" : "Activate as admin"} 
                            onClick={() => {
                              handleSetAdmin(user)
                              setOptionIndex(null)
                            }}>
                              {user.privilege === "admin" ? "Deactivate as admin" : "Activate as admin"}
                            </button>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdAccountCompanies;
