import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import errorPage from "../img/errorPage.jpg";

const ErrorPage = () => {

  const navigate = useNavigate();
  return (
    <div>

      <SideBar />

      <ContentNavbar />

      <div className='main-content'>
        <div className="content error-content">
          <img src={errorPage} alt="error" />
          {/* <h1>Error!</h1> */}
          <p>Looks like you are not authorized to view this page!</p>
          <button type='button' onClick={() => navigate("/home")}> <FontAwesomeIcon icon={ faArrowLeft } /> Go home</button>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
