import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastNotification from './ToastNotification';
import Spinner from './Spinner';

import {
  faEye,
  faEnvelope,
  faLock,
  faUser,
  faClipboardUser,
  faCircleUser,
  faCheckCircle,
  faWarning,
  faBox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import create from '../img/signup.png';
import logo from '../img/logo.png';

const CreateAccount = () => {
  const [submit, setSubmit] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [signUpDetails, setSignUpDetails] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    setSignUpDetails((previousDetails) => {
      return { ...previousDetails, [e.target.name]: e.target.value };
    });
  };

  const [organizations, setOrganizations] = useState([]);
  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/organization/findAll`)
      .then((res) => {
        setOrganizations(res.data);
        setTimeout(() => setLoading(false), 3000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 3000);
      });
  }, []);

  const signup = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmit(true);

    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/user`, signUpDetails)

      .then((res) => {
        setMessage({
          type: 'success',
          msg: res.data.message,
        });
        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
          setLoading(false);
          setSubmit(false);

          localStorage.setItem('accessToken', res.data.access_token);
          localStorage.setItem('user', 'true');
          navigate('/home');
        }, 3000);
      })

      .catch((err) => {
        setLoading(false);
        setSubmit(false);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 8000);
      });
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  const myFunction = () => {
    var x = document.getElementById('myInput');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };

  return (
    <div className="login">
      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.type === 'error' ? message.msg : 'Signup successful'}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && submit && <Spinner />}

      <div className="box2-signup">
        <div className="logo-login">
          <img src={logo} alt="Folham" />
        </div>

        <h1>Create Account</h1>
        <div className="box-bottom">
          Already a member?{' '}
          <span>
            {' '}
            <Link to="/"> Log in</Link>{' '}
          </span>
        </div>

        <div className="box2-mini">
          <div className="bx-mini-cnt">
            <form onSubmit={signup}>
              <div className="mini1">
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="firstName"
                    value={signUpDetails.firstName}
                    placeholder="First Name"
                    required
                  />
                </div>
              </div>

              <div className="mini1">
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="lastName"
                    value={signUpDetails.lastName}
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>

              <div className="mini1">
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faClipboardUser}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="username"
                    value={signUpDetails.username}
                    placeholder="Username"
                    required
                  />
                </div>
              </div>

              <div className="mini1">
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="text"
                    id="email"
                    name="email"
                    value={signUpDetails.email}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <div className="mini1">
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faBox}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />

                  <select
                    name="organizationId"
                    id="organizationId"
                    onChange={handleInputChange}
                    // style={{ marginTop: '1rem' }}
                  >
                    <option style={{ color: 'lightgray ' }} value="">
                      Select Organization
                    </option>
                    {organizations.map((org, i) => {
                      return (
                        <option key={org._id} value={org._id}>
                          {org.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mini1">
                <div style={{ flex: 3 }}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ color: '#797D87', fontSize: '16px' }}
                  />
                  <input
                    onChange={handleInputChange}
                    type="password"
                    name="password"
                    value={signUpDetails.password}
                    id="myInput"
                    placeholder="Password"
                    required
                  />
                </div>
                <FontAwesomeIcon
                  icon={faEye}
                  className="box1-icon"
                  style={{ color: '#797D87' }}
                  onClick={() => myFunction()}
                />
              </div>

              <div className="mini2">
                <p>Password must be at least 8 characters </p>
              </div>

              <button type="submit" className="btn-signup" disabled={loading}>
                Create an account
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="box1">
        <img src={create} alt="" className="box-image" />
      </div>
    </div>
  );
};

export default CreateAccount;
