import React, { useState, useEffect, useMemo } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import axios from 'axios';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileScreen,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import DatalistInput from 'react-datalist-input';
// import 'react-datalist-input/dist/styles.css';

const ActivityLog = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [profile, setProfile] = useState({});
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalLogs, setTotalLogs] = useState([]);
  const [totalUserLogs, setTotalUserLogs] = useState([]);
  const [currentLogs, setCurrentLogs] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [logsPerPage] = useState(10);
  const [dateLogs, setDateLogs] = useState([]);
  const [skip, setSkip] = useState(0);
  const navigation = [];

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    if (localStorage.getItem('profile')) {
      setProfile(JSON.parse(localStorage.getItem('profile')));
    } else {
      setTimeout(() => {
        setProfile(JSON.parse(localStorage.getItem('profile')));
      }, 5000);
    }
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/activity-logs`, { headers })
      .then((res) => {
        setTotalLogs(res.data.length);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/activity-logs?query=${userId}`,
        { headers },
      )
      .then((res) => {
        setTotalUserLogs(res.data.length);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });

    axios
      .get(
        `${process.env.REACT_APP_API_BASEURL}/activity-logs?limit=${logsPerPage}&skip=${skip}&query=${userId}`,
        { headers },
      )
      .then((res) => {
        setCurrentLogs(res.data);
        setTimeout(() => setLoading(false), 2000);
        if (skip === -10) {
          setSkip(0);
          setActivePage(1);
        }
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token, skip, logsPerPage, userId]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/all-users`, { headers })
      .then((res) => {
        setUsers(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  // const handleNavigate = (e, index) => {
  //   e.preventDefault();

  //   setSkip(logsPerPage * index - logsPerPage);
  //   setActivePage(index);
  // };

  for (
    let i = 1;
    i <= Math.ceil(totalLogs / 10) && i <= Math.ceil(totalUserLogs / 10);
    i++
  ) {
    if (
      i <= 5 ||
      i === Math.ceil(totalLogs / 10) ||
      i === Math.ceil(totalUserLogs / 10) ||
      Math.abs(activePage - i) <= 1
    ) {
      navigation.push(i);
    }
  }

  useEffect(() => {
    let d = [];
    let all = [];
    currentLogs.forEach((log) => {
      if (!d.includes(log?.createdAt.substr(0, 10))) {
        d.push(log?.createdAt.substr(0, 10));
      } else {
        d.splice(
          d.indexOf(log?.createdAt.substr(0, 10)),
          1,
          log?.createdAt.substr(0, 10),
        );
      }
    });

    d.forEach((date) => {
      let fil = currentLogs.filter(
        (log) => log?.createdAt.substr(0, 10) === date,
      );
      all.push({ date, logs: fil });
    });
    setDateLogs(all);
  }, [currentLogs]);

  useEffect(() => {
    setActivePage(1);
    setSkip(0);
  }, [userId]);

  const allUsers = useMemo(
    () =>
      users.map((user) => ({
        id: user._id,
        value: `${user.firstName} ${user.lastName}`,
        ...user,
      })),
    [users],
  );

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">activity log</div>

          {(profile?.privilege === 'superAdmin' ||
            (profile?.privilege === 'admin' && profile.organizationId)) && (
            <form className="search-group">
              <div className="search-box">
                <DatalistInput
                  placeholder="Filter by user"
                  onSelect={(user) => setUserId(user._id)}
                  items={allUsers}
                />
              </div>
            </form>
          )}

          <div className="addnew-container activitylog-container">
            <div className="addnew-content" style={{ margin: '0rem 0rem' }}>
              {!loading &&
                dateLogs.map((log, i) => (
                  <div key={i}>
                    <div className="date-heading">
                      <h4 className="date">{log.date}</h4>
                    </div>
                    {log.logs.map((l, i) => (
                      <div key={i} className="activitylog-item">
                        <div className="activitylog-time">
                          <h4>{l.createdAt.substr(11, 5)}</h4>
                        </div>
                        <div
                          className="activitylog-content"
                          style={{ padding: '20px' }}
                        >
                          <div className="icon-box">
                            <FontAwesomeIcon
                              icon={faMobileScreen}
                              className="icon"
                            />
                          </div>
                          <div>
                            <h4 style={{ fontWeight: 500 }}>{l.message}</h4>
                            {/* <p>{log.createdAt.substr(0, 10)}</p> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              {!loading && currentLogs.length === 0 && (
                <div className="no-activitylog">
                  <h4>No activity history </h4>
                </div>
              )}
            </div>
            {totalLogs > 10 && totalUserLogs > 10 && (
              <div className="page-navigation">
                <button
                  type="button"
                  title="Previous"
                  onClick={() => {
                    setSkip(skip - logsPerPage);
                    setActivePage(activePage - 1);
                  }}
                  disabled={activePage === 1}
                  className={
                    activePage === 1
                      ? 'prev disabled navigation-btn'
                      : 'prev navigation-btn'
                  }
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                </button>
                <span>Page {activePage}</span>
                <button
                  type="button"
                  title="Next"
                  onClick={() => {
                    setSkip(skip + logsPerPage);
                    setActivePage(activePage + 1);
                  }}
                  disabled={
                    activePage === Math.ceil(totalLogs / logsPerPage) ||
                    activePage === Math.ceil(totalUserLogs / logsPerPage)
                  }
                  className={
                    activePage === Math.ceil(totalLogs / logsPerPage) ||
                    activePage === Math.ceil(totalUserLogs / logsPerPage)
                      ? 'next disabled navigation-btn'
                      : 'next navigation-btn'
                  }
                >
                  <FontAwesomeIcon icon={faAngleRight} className="back-icon" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
