/* eslint-disable no-unused-vars */
import {
  faAngleLeft,
  faAngleRight,
  faMobileScreen,
  faCheckCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import DatalistInput from 'react-datalist-input';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import Spinner from '../components/Spinner';
import { ExportToCsv } from '../export-to-csv/build/export-to-csv';
import ToastNotification from '../components/ToastNotification';

const DeviceLog = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [currentLogs, setCurrentLogs] = useState([]);
  const [skip, setSkip] = useState(0);
  const [logsPerPage] = useState(100);
  const [activePage, setActivePage] = useState(1);
  const [screenId, setScreenId] = useState('');
  const [screens, setScreens] = useState([]);
  const [accountId, setAccountId] = useState('');
  const [adAccounts, setAdAccounts] = useState([]);
  const [dateLogs, setDateLogs] = useState([]);
  const [deviceLogs, setDeviceLogs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    let url = new URL(
      `${process.env.REACT_APP_API_BASEURL}/device-logs/logs?limit=${logsPerPage}&skip=${skip}`,
    );
    setUrl(url);
  }, [logsPerPage, skip]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/device-logs/logs`, { headers })
      .then((res) => {
        setDeviceLogs(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });

    if (screenId.length > 0 || accountId.length > 0 || startDate.length > 0) {
      axios
        .get(url, { headers })
        .then((res) => {
          setCurrentLogs(res.data);
          setTimeout(() => setLoading(false), 2000);
          if (skip === -10) {
            setSkip(0);
            // setActivePage(1);
          }
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/device-logs/logs?limit=${logsPerPage}&skip=${skip}`,
          { headers },
        )
        .then((res) => {
          setCurrentLogs(res.data);
          setTimeout(() => setLoading(false), 2000);
          if (skip === -10) {
            setSkip(0);
            // setActivePage(1);
          }
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
        });
    }
  }, [token, skip, logsPerPage, screenId, accountId, startDate, url]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    if (screenId.length > 0) {
      setLoading(true);

      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/ad-accounts/screen/${screenId}`,
          {
            headers,
          },
        )
        .then((res) => {
          const data = [];
          for (const d of res.data) {
            for (const a of d.accounts) {
              data.push(a);
            }
          }
          setAdAccounts(data);
          setTimeout(() => setLoading(false), 2000);
        })
        .catch((err) => {
          setTimeout(() => setLoading(false), 2000);
        });
    }
    // else {
    //   setScreenCampaigns([]);
    // }
  }, [token, screenId]);

  const allScreens = useMemo(
    () =>
      screens.map((screen) =>
        screen
          ? {
              id: screen._id,
              value: `${screen.name}`,
              ...screen,
            }
          : {
              id: '',
              value: '',
              ...screen,
            },
      ),
    [screens],
  );

  const allAdAccounts = useMemo(
    () =>
      adAccounts.map((adAccount) =>
        adAccount
          ? {
              id: adAccount._id,
              value: `${adAccount.name}`,
              ...adAccount,
            }
          : {
              id: '',
              value: '',
              ...adAccount,
            },
      ),
    [adAccounts],
  );

  const convertToCsv = (e) => {
    e.preventDefault();

    if (startDate === '') {
      setMessage({
        type: 'error',
        msg: 'Please select start date.',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 5000);
    } else {
      const options = {
        fieldSeparator: ',',
        showLabels: true,
        showTitle: true,
        title: `Device logs for ${startDate.substring(
          0,
          10,
        )}, ${startDate.substring(11, 16)}`,
        useTextFile: false,
        useBom: true,
        // useKeysAsHeaders: true,
        headers: [
          'Ad ID',
          'Logged On',
          'Campaign Name',
          'Ad Account Name',
          'Screen Name',
          'Screen ID Name',
        ],
      };

      const csvExporter = new ExportToCsv(options);
      let logs = [...currentLogs];

      logs.forEach((s) => {
        delete s.messageType;
        delete s.screenId;
        delete s.adType;
        delete s.campaignId;
        delete s.adFileName;
        delete s.__v;
      });

      csvExporter.generateCsv(logs);
    }
  };

  useEffect(() => {
    let d = [];
    let all = [];
    currentLogs
      .sort((a, b) => (a.loggedOn > b.loggedOn ? -1 : 1))
      .forEach((log) => {
        if (!d.includes(log?.loggedOn.substr(0, 10))) {
          d.push(log?.loggedOn.substr(0, 10));
        } else {
          d.splice(
            d.indexOf(log?.loggedOn.substr(0, 10)),
            1,
            log?.loggedOn.substr(0, 10),
          );
        }
      });

    d.forEach((date) => {
      let fil = currentLogs.filter(
        (log) => log?.loggedOn.substr(0, 10) === date,
      );
      all.push({ date, logs: fil });
    });
    setDateLogs(all);
  }, [currentLogs]);

  useEffect(() => {
    let date = new Date().toISOString().substr(0, 16);
    setCurrentDate(date);
  }, []);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Device log - Daily</div>

          <form className="search-group">
            <div className="search-box">
              <DatalistInput
                placeholder="Select screen"
                onSelect={(screen) => {
                  setScreenId(screen._id);
                  if (url.href?.includes('screenId')) {
                    url.searchParams.set('screenId', screen._id);
                  } else {
                    url.searchParams.append('screenId', screen._id);
                  }
                }}
                items={allScreens}
              />
            </div>
            <div className="search-box">
              <DatalistInput
                placeholder="Select ad account"
                onSelect={(account) => {
                  setAccountId(account._id);
                  if (url.href?.includes('accountId')) {
                    url.searchParams.set('accountId', account._id);
                  } else {
                    url.searchParams.append('accountId', account._id);
                  }
                }}
                items={allAdAccounts}
              />
            </div>
          </form>

          <form>
            <div>
              <label htmlFor="start">Select day</label>
              <input
                className="date-input"
                type="datetime-local"
                name="start"
                id="start"
                onChange={(e) => {
                  setStartDate(e.target.value);
                  if (url.href?.includes('startDate')) {
                    url.searchParams.set('startDate', e.target.value);
                  } else {
                    url.searchParams.append('startDate', e.target.value);
                  }
                }}
              />
            </div>
            <button className="csv-btn" onClick={convertToCsv}>
              Export as CSV
            </button>
          </form>

          {/* <div className="second-title">Your activity since signup</div> */}

          <div className="addnew-container activitylog-container">
            <div className="addnew-content">
              {!loading &&
                dateLogs.map((log, i) => (
                  <div key={i}>
                    <div className="date-heading">
                      <h4 className="date">{log.date}</h4>
                    </div>
                    {log.logs.map((l, i) => (
                      <div key={i} className="activitylog-item">
                        <div className="activitylog-time">
                          <h4>{l.loggedOn.substr(11, 5)}</h4>
                        </div>
                        <div className="activitylog-content">
                          <div
                            className={`icon-box ${
                              l.messageType === 'fail' ? 'failed' : ''
                            } ${
                              l.messageType === 'canceled' ? 'cancelled' : ''
                            }`}
                          >
                            <FontAwesomeIcon
                              icon={faMobileScreen}
                              className="icon"
                            />
                          </div>
                          <div className="log-info">
                            <h4>Ad ID: {l.adId}</h4>
                            {/* <span>Campaign: {l.campaignName}</span> */}
                            <span>Status: {l.messageType}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              {!loading && currentLogs.length === 0 && (
                <div className="no-activitylog">
                  <h4>No activity history </h4>
                </div>
              )}
            </div>
            {currentLogs.length === 100 && (
              <div className="page-navigation">
                <button
                  type="button"
                  title="Previous"
                  onClick={() => {
                    setSkip(skip - logsPerPage);
                    setActivePage(activePage - 1);
                  }}
                  disabled={activePage === 1}
                  className={
                    activePage === 1
                      ? 'prev disabled navigation-btn'
                      : 'prev navigation-btn'
                  }
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                </button>
                <span>Page {activePage}</span>
                <button
                  type="button"
                  title="Next"
                  onClick={() => {
                    setSkip(skip + logsPerPage);
                    setActivePage(activePage + 1);
                  }}
                  disabled={
                    //   activePage === Math.ceil(deviceLogs.length / logsPerPage) ||
                    //   activePage === Math.ceil(deviceLogs.length / logsPerPage)
                    currentLogs.length < 100
                  }
                  className={
                    // activePage === Math.ceil(deviceLogs.length / logsPerPage) ||
                    // activePage === Math.ceil(deviceLogs.length / logsPerPage)
                    currentLogs.length < 100
                      ? 'next disabled navigation-btn'
                      : 'next navigation-btn'
                  }
                >
                  <FontAwesomeIcon icon={faAngleRight} className="back-icon" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceLog;
