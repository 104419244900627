import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Weather = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [city, setCity] = useState('');
  const [weatherDetails, setWeatherDetails] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    if (city.length > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASEURL}/screen-weather-details/${city}`,
          {
            headers,
          },
        )
        .then((res) => {
          setWeatherDetails(res.data);
        })
        .catch((err) => {});
    }
  }, [token, city]);

  useEffect(() => {
    setCity(
      window.location.pathname.split('/')[
        window.location.pathname.split('/').length - 1
      ],
    );
  }, []);

  return (
    <div className="container">
      <div className="widget">
        <div className="weather-details">
          <div className="temperature">{weatherDetails.temperature}</div>
          <div className="summary">
            <p className="summaryText">{weatherDetails.weatherDescription}</p>
          </div>
          <div className="precipitation">
            Humidity: {weatherDetails.humidity}
          </div>
          <div className="wind">Wind: {weatherDetails.windSpeed}</div>
        </div>
        <div className="pictoBackdrop"></div>
        <div className="pictoFrame"></div>
        <div className="pictoCloudGroup">
          <div className="pictoCloudBig"></div>
          <div className="pictoCloudFill"></div>
          <div className="pictoCloudSmall"></div>
        </div>
        <div className="iconCloudBig"></div>
        <div className="iconCloudFill"></div>
        <div className="iconCloudSmall"></div>
      </div>
    </div>
  );
};

export default Weather;
