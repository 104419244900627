import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import Home from "../pages/Home";

const useAuth = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false || window.localStorage.getItem("user") === "true");
  useEffect(() => {
    setIsLoggedIn(JSON.parse(localStorage.getItem("user")));
  }, []);
  
  if (isLoggedIn) {
    return true;
  } else {
    return false;
  } 
};

const PublicRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Home /> : <Outlet />;
};

export default PublicRoutes;
