import React, { useEffect, useRef, useState } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import {
  faPlus,
  faXmark,
  faCheckCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AWS from 'aws-sdk';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';

const Ads = () => {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [file, setFile] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [screens, setScreens] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [filterAdAccounts, setfilterAdAccounts] = useState([]);
  const [progress, setProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [adArray, setAdArray] = useState([]);
  const [privilege, setPrivilege] = useState('');
  const [organizations, setOrganizations] = useState([]);
  // const [edit, setEdit] = useState(false);
  const [adDetails, setAdDetails] = useState({
    adType: '',
    adName: '',
    adUrl: '',
  });
  // const [detailsGroup, setDetailsGroup] = useState([]);
  const [ad, setAd] = useState({
    adScreen: '',
    adAccount: '',
    organizationId: '',
    adFile: [
      {
        adType: '',
        adUrl: '',
        adName: '',
      },
    ],
  });
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });

  const btnRef = useRef(null);

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('profile'));
    if (roles) {
      setPrivilege(roles.privilege);
    }

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/organization/findAll`)
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
        // setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        // setTimeout(() => setLoading(false), 2000);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, { headers })
      .then((res) => {
        setAdAccounts(res.data);
        // setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        // setTimeout(() => setLoading(false), 2000);
      });
  }, [token]);

  const handleFileUpload = (e, index) => {
    e.preventDefault();

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const S3 = new AWS.S3();

    const objParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME
        ? process.env.REACT_APP_BUCKET_NAME
        : console.error('Bucket name not provided'),
      Key: file.name,
      Body: file,
      ContentType: file.type,
    };

    setProgress(true);
    if (file.size > 2 * 1000000) {
      setMessage({
        type: 'error',
        msg: 'File size is too large! Size should not be more than 2MB.',
      });
      setProgress(false);
    } else {
      S3.putObject(objParams)
        .on('httpUploadProgress', function ({ loaded, total }) {
          setPercentage(Math.round((loaded / total) * 100));
        })
        .send((err, data) => {
          if (data) {
            setProgress(false);
            setTimeout(() => {
              // setPercentage(0)
            }, 2000);

            ad.adFile.forEach((a, i) => {
              if (a.adName === '' && a.adUrl === '' && a.adType === '') {
                setAd({
                  ...ad,
                  adFile: [
                    ...ad.adFile.slice(0, i),
                    {
                      adType: adDetails.adType,
                      adName: adDetails.adName,
                      adUrl:
                        process.env.REACT_APP_FOLHAM_S3_URL + `/${file.name}`,
                    },
                  ],
                });
              }
              if (i === index) {
                setAd({
                  ...ad,
                  adFile: [
                    ...ad.adFile.slice(0, i),
                    {
                      adType: adDetails.adType,
                      adName: adDetails.adName,
                      adUrl:
                        process.env.REACT_APP_FOLHAM_S3_URL + `/${file.name}`,
                    },
                  ],
                });
              }
            });
          } else {
            setProgress(false);
            setTimeout(() => {
              // setPercentage(0)
            }, 2000);
          }
        });
    }
  };

  const handleVastAndHtml = (e, index) => {
    e.preventDefault();

    ad.adFile.forEach((a, i) => {
      if (a.adName === '' && a.adUrl === '' && a.adType === '') {
        setAd({
          ...ad,
          adFile: [
            ...ad.adFile.slice(0, i),
            {
              adType: adDetails.adType,
              adName: adDetails.adName,
              adUrl: adDetails.adUrl,
            },
          ],
        });
      }
      if (i === index) {
        setAd({
          ...ad,
          adFile: [
            ...ad.adFile.slice(0, i),
            {
              adType: adDetails.adType,
              adName: adDetails.adName,
              adUrl: adDetails.adUrl,
            },
          ],
        });
      }
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAdNameChange = (e) => {
    setAdDetails({ ...adDetails, adName: e.target.value });
  };

  const handleAdTypeChange = (e) => {
    setAdDetails({ ...adDetails, adType: e.target.value });
  };

  const handleAdUrlChange = (e) => {
    setAdDetails({ ...adDetails, adUrl: e.target.value });
  };

  const handleInputChange = (e) => {
    setAd({
      ...ad,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    if (ad.adScreen === '' || ad.adAccount === '' || ad.adFile.length === 0) {
      setMessage({
        type: 'error',
        msg: 'Please fill all fields!',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 6000);
    } else {
      ad.adFile = ad.adFile.filter((a) => a.adName !== '' && a.adUrl !== '');

      setSubmitLoading(true);

      axios
        .post(`${process.env.REACT_APP_API_BASEURL}/ads/create`, ad, {
          headers,
        })
        .then((res) => {
          setSubmitLoading(false);
          setAdArray([...adArray, res.data]);
          setMessage({
            type: 'success',
            msg: 'Ad created successfully!',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 6000);

          setAd({
            adScreen: '',
            adAccount: '',
            adFile: [
              {
                adType: '',
                adUrl: '',
                adName: '',
              },
            ],
            adType: '',
          });
          setAdDetails({
            adType: '',
            adName: '',
            adUrl: '',
          });
          document.querySelectorAll('input').forEach((i) => {
            i.value = '';
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          setSubmitLoading(false);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 6000);
        });
    }
  };

  const addToFiles = (e) => {
    e.preventDefault();

    setPercentage(0);
    setAdDetails({
      adType: '',
      adUrl: '',
      adName: '',
    });
    setAd({
      ...ad,
      adFile: [
        ...ad.adFile,
        {
          adType: '',
          adName: '',
          adUrl: '',
        },
      ],
    });

    // setTimeout(() => {
    //   setFile(null);
    // }, 1000);         //====== CHECK LATER ======//
  };

  const handleScreenChange = (e) => {
    var value = e.target.value;

    const newAds = adAccounts.filter((x) => x.adScreen === value);
    setfilterAdAccounts(newAds);
  };

  const handleDelete = (e, index) => {
    e.preventDefault();

    let all = document.querySelectorAll('.box1-adcontainer');
    all.forEach((a, i) => {
      if (i === index) {
        ad.adFile.splice(index, 1);

        setAd({
          ...ad,
          adFile: ad.adFile,
        });

        // all[index].style.display = "none";
      }
    });
  };

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    if (btnRef.current && btnRef.current.innerText === 'Done!') {
      setTimeout(() => {
        setPercentage(0);
        btnRef.current.style.display = 'none';
      }, 2000);
    } else if (btnRef.current && btnRef.current.innerText !== 'Done!') {
      btnRef.current.style.display = 'block';
    }
  }, [progress, percentage, btnRef.current?.innerText]);

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current.style.display = 'block';
    }
  }, [adDetails.adName, file]);

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      <div className="main-content">
        <div className="content">
          <div className="first-title">Ads</div>

          <div className="adaccount-container">
            <div className="ads-box1">
              <div className="ads-box1mini">
                <form onSubmit={handleSubmit}>
                  {privilege === 'superAdmin' && (
                    <select name="organizationId" onChange={handleInputChange}>
                      <option value="">Select organization</option>
                      {organizations ? (
                        organizations.map((org, i) => (
                          <option key={i} value={org._id}>
                            {org.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Organization </option>
                      )}
                    </select>
                  )}
                  <select
                    id="ad-screen"
                    onChange={(e) => {
                      handleInputChange(e);
                      handleScreenChange(e);
                    }}
                    name="adScreen"
                  >
                    <option>Choose Screen</option>
                    {screens.map((screen, i) => {
                      return (
                        <option key={i} value={screen._id}>
                          {screen.name}
                        </option>
                      );
                    })}
                  </select>

                  <select
                    id="ad-account"
                    onChange={handleInputChange}
                    name="adAccount"
                  >
                    <option>Select Ad Account</option>
                    {filterAdAccounts.map((account, i) => {
                      return (
                        <option key={i} value={account._id}>
                          {account.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="ad-file-container">
                    {ad.adFile.map((f, index) => {
                      return (
                        <div className="box1-adcontainer" key={index}>
                          <div className="ads-details">
                            <h3>Select Ad File</h3>{' '}
                            {ad.adFile.length > 1 ? (
                              <span onClick={(e) => handleDelete(e, index)}>
                                <FontAwesomeIcon icon={faXmark} />{' '}
                              </span>
                            ) : null}
                          </div>
                          <select
                            onChange={handleAdTypeChange}
                            name="adType"
                            id=""
                          >
                            <option value="">Select Ad Type</option>
                            <option value="picture">Picture</option>
                            <option value="video">Video</option>
                            {/* <option value="vast">Vast</option> */}
                            <option value="Html-tags">HTML Tags</option>
                          </select>

                          <div className="ads-buttons">
                            {(adDetails.adType === 'picture' ||
                              adDetails.adType === 'video') &&
                              ad.adFile[index].adName === '' &&
                              ad.adFile[index].adUrl === '' && (
                                <>
                                  <input
                                    type="file"
                                    name="file"
                                    accept="image/*, video/*"
                                    onChange={handleFileChange}
                                    // value={f.adUrl}
                                    style={{
                                      background: '#EBEAEB',
                                      border: '1px solid #797D87',
                                    }}
                                  />
                                </>
                              )}
                            {adDetails.adType === 'vast' &&
                              ad.adFile[index].adName === '' &&
                              ad.adFile[index].adUrl === '' && (
                                <input
                                  type="text"
                                  name="adUrl"
                                  placeholder="Vast URL"
                                  // value={f.adName}
                                  onChange={handleAdUrlChange}
                                  style={{
                                    background: '#fff',
                                    color: '#2E3543',
                                    border: '1px solid #797D87',
                                  }}
                                />
                              )}
                            {adDetails.adType === 'Html-tags' &&
                              ad.adFile[index].adName === '' &&
                              ad.adFile[index].adUrl === '' && (
                                <input
                                  type="text"
                                  name="adUrl"
                                  placeholder="HTML tags"
                                  // value={f.adName}
                                  onChange={handleAdUrlChange}
                                  style={{
                                    background: '#fff',
                                    color: '#2E3543',
                                    border: '1px solid #797D87',
                                  }}
                                />
                              )}
                            {adDetails.adType !== '' &&
                              ad.adFile[index].adName === '' &&
                              ad.adFile[index].adUrl === '' && (
                                <input
                                  type="text"
                                  name="adName"
                                  placeholder="Ad Name"
                                  // value={f.adName}
                                  onChange={handleAdNameChange}
                                  style={{
                                    background: '#fff',
                                    color: '#2E3543',
                                    border: '1px solid #797D87',
                                  }}
                                />
                              )}
                          </div>
                          {f.adName !== '' &&
                            f.adType !== '' &&
                            f.adUrl !== '' && (
                              <div className="ad-info">
                                {f.adType === 'picture' ||
                                f.adType === 'video' ? (
                                  <h3>
                                    File: <span>{f.adUrl.substring(32)}</span>
                                  </h3>
                                ) : (
                                  <h3>
                                    URL: <span>{f.adUrl}</span>
                                  </h3>
                                )}
                                <h3>
                                  Ad Name: <span>{f.adName}</span>
                                </h3>
                                {/* <button type="button" onClick={() => setEdit(true)}>Edit</button> */}
                              </div>
                            )}

                          {ad.adFile.length - 1 === index && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                disabled={
                                  adDetails.adName === '' ||
                                  adDetails.adType === '' ||
                                  (percentage < 100 && progress)
                                }
                                ref={btnRef}
                                id="ads-btn"
                                className={
                                  adDetails.adName === '' ||
                                  adDetails.adType === '' ||
                                  (percentage < 100 && progress)
                                    ? 'ads-btn disabled'
                                    : 'ads-btn'
                                }
                                onClick={(e) => {
                                  if (
                                    adDetails.adType === 'picture' ||
                                    adDetails.adType === 'video'
                                  ) {
                                    handleFileUpload(e, index);
                                  } else {
                                    handleVastAndHtml(e, index);
                                  }
                                }}
                                title="Upload Ad File"
                              >
                                {adDetails.adType === 'picture' ||
                                adDetails.adType === 'video' ? (
                                  <>
                                    {percentage < 100 &&
                                      !progress &&
                                      'Upload File'}
                                    {percentage < 100 &&
                                      progress &&
                                      `Uploading... ${percentage}%`}
                                    {percentage === 100 && !progress && 'Done!'}
                                    <span
                                      className="progress-loader"
                                      style={{ width: `${percentage}%` }}
                                    ></span>
                                  </>
                                ) : (
                                  <>Add</>
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="ads-buttons">
                    <button
                      disabled={
                        ad.adFile[ad.adFile.length - 1].adName === '' &&
                        ad.adFile[ad.adFile.length - 1].adUrl === '' &&
                        ad.adFile[ad.adFile.length - 1].adType === ''
                      }
                      // className="add-file"
                      className={
                        ad.adFile.length < 1 ||
                        (ad.adFile[ad.adFile.length - 1].adName === '' &&
                          ad.adFile[ad.adFile.length - 1].adUrl === '' &&
                          ad.adFile[ad.adFile.length - 1].adType === '')
                          ? 'add-file disabled'
                          : 'add-file'
                      }
                      style={{ background: '#23C48B', color: '#fff' }}
                      onClick={addToFiles}
                      title="Add More Ad Files"
                    >
                      {' '}
                      <FontAwesomeIcon icon={faPlus} /> add another file
                    </button>

                    <button
                      type="submit"
                      // className="submit"
                      className={
                        ad.adFile.length < 1 ||
                        (ad.adFile[ad.adFile.length - 1].adName === '' &&
                          ad.adFile[ad.adFile.length - 1].adUrl === '' &&
                          ad.adFile[ad.adFile.length - 1].adType === '')
                          ? 'submit disabled'
                          : 'submit'
                      }
                      style={{
                        background: '#0651DF',
                        color: '#fff',
                        cursor: 'pointer',
                      }}
                      title="Submit Ad"
                      disabled={
                        ad.adFile.length < 1 ||
                        (ad.adFile[ad.adFile.length - 1].adName === '' &&
                          ad.adFile[ad.adFile.length - 1].adUrl === '' &&
                          ad.adFile[ad.adFile.length - 1].adType === '')
                      }
                      // disabled={ad.adFile.length < 1}
                    >
                      {submitLoading ? <Spinner /> : 'Finish & Submit'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
