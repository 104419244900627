import React, { useState, useEffect } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ToastNotification from '../components/ToastNotification';
import Spinner from '../components/Spinner';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ScreenViewPort from '../components/ScreenViewPort';

const CreateCampaign = () => {
  const [show1, setShow1] = useState(false);
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [submitLoading, setSubmitLoading] = useState(false);
  const [adAccounts, setAdAccounts] = useState([]);
  const [ads, setAds] = useState([]);
  const [screens, setScreens] = useState([]);
  const [adAccountAds, setAdAccountAds] = useState([]);
  const [privilege, setPrivilege] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const [filterAdAccounts, setFilterAdAccounts] = useState([]);

  const [campaign, setCampaign] = useState({
    adAccount: '',
    name: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    adFiles: [],
    campaignScheduleDays: [],
    screenViewPort: 1,
    videoAndImageDuration: 0,
    organizationId: '',
  });

  const inputRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('profile'));
    if (roles) {
      setPrivilege(roles.privilege);
    }

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/organization/findAll`)
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    if (e.target.type === 'range') {
      setCampaign((prevDetails) => {
        return { ...prevDetails, [e.target.name]: parseInt(e.target.value) };
      });
    } else if (e.target.type === 'radio') {
      setCampaign((prevDetails) => {
        return { ...prevDetails, [e.target.name]: parseInt(e.target.value) };
      });
    } else {
      setCampaign((prevDetails) => {
        return { ...prevDetails, [e.target.name]: e.target.value };
      });
    }
  };

  const handleScreenChange = (e) => {
    // filter ad accounts by screen id
    const filterAdAccounts = adAccounts.filter(
      (account) => account.adScreen === e.target.value,
    );
    setFilterAdAccounts(filterAdAccounts);
  };

  const handleAdFileSelect = (e) => {
    if (campaign.adFiles.includes(e.target.value)) {
      const filterAds = campaign.adFiles.filter(
        (file) => file !== e.target.value,
      );
      setCampaign((prevDetails) => {
        return { ...prevDetails, adFiles: filterAds };
      });
    } else {
      setCampaign((prevDetails) => {
        return {
          ...prevDetails,
          adFiles: [...campaign.adFiles, e.target.value],
        };
      });
    }
  };

  const handleCampaignChange = (e) => {
    if (campaign.campaignScheduleDays.includes(e.target.value)) {
      const filterCampaign = campaign.campaignScheduleDays.filter(
        (day) => day !== e.target.value,
      );
      setCampaign((prevDetails) => {
        return { ...prevDetails, campaignScheduleDays: filterCampaign };
      });
    } else {
      setCampaign((prevDetails) => {
        return {
          ...prevDetails,
          campaignScheduleDays: [
            ...campaign.campaignScheduleDays,
            e.target.value,
          ],
        };
      });
    }
  };

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ads/get/all-ads`, {
        headers,
      })
      .then((res) => {
        setAds(res.data);
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    let filteredAds = ads.filter((ad) => ad.adAccount === campaign.adAccount);
    setAdAccountAds(filteredAds);
  }, [ads, campaign.adAccount]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-screens/ad-screens`, {
        headers,
      })
      .then((res) => {
        setScreens(res.data);
      })
      .catch((err) => {});
  }, [token]);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/ad-accounts`, { headers })
      .then((res) => {
        setAdAccounts(res.data);
        // (res.data);
      })
      .catch((err) => {});
  }, [token]);

  const createCampaignSubmit = (e) => {
    e.preventDefault();

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    if (
      campaign.name === '' ||
      campaign.adAccount === '' ||
      campaign.startDate === '' ||
      campaign.endDate === '' ||
      campaign.startTime === '' ||
      campaign.endTime === '' ||
      campaign.campaignScheduleDays.length === 0
    ) {
      setMessage({
        type: 'error',
        msg: 'Please fill all the fields',
      });

      setTimeout(() => {
        setMessage({
          type: '',
          msg: '',
        });
      }, 6000);
    } else {
      setSubmitLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}/campaign/campaigns/create`,
          campaign,
          { headers },
        )
        .then((res) => {
          setSubmitLoading(false);
          setMessage({
            type: 'success',
            msg: 'Campaign created successfully!',
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
            navigate('/campaignschedule');
          }, 3000);
          document
            .querySelectorAll('input[type=checkbox]')
            .forEach(function (el) {
              el.checked = false;
            });
          setCampaign({
            adAccount: '',
            name: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            adFiles: [],
            campaignScheduleDays: [],
            videoAndImageDuration: 0,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          setSubmitLoading(false);
          setMessage({
            type: 'error',
            msg: err.response.data.message,
          });

          setTimeout(() => {
            setMessage({
              type: '',
              msg: '',
            });
          }, 8000);
        });
    }
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      <div className="main-content">
        <div className="content">
          <div className="first-title">create campaign</div>

          <div className="campaign-container">
            <form onSubmit={createCampaignSubmit}>
              <div className="fake-form">
                <div className="campaign-content">
                  {privilege === 'superAdmin' && (
                    <>
                      <label>Organization Name </label>
                      <select
                        name="organizationId"
                        onChange={handleInputChange}
                      >
                        <option value="">Select organization</option>
                        {organizations ? (
                          organizations.map((org, i) => (
                            <option key={i} value={org._id}>
                              {org.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No Organization </option>
                        )}
                      </select>
                    </>
                  )}
                  <label>Campaign Name </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Campaign Name"
                    value={campaign.name}
                    onChange={handleInputChange}
                  />

                  <label>Select Screen ID/Name </label>
                  <select
                    name="adScreen"
                    id="ad-screen"
                    onChange={handleScreenChange}
                  >
                    <option>Select Screen ID/Name</option>
                    {screens.map((screen, i) => {
                      return (
                        <option key={i} value={screen._id}>
                          {screen.screenId} - {screen.name}
                        </option>
                      );
                    })}
                  </select>

                  <label>Select Ad Account </label>
                  <select
                    name="adAccount"
                    id="ad-account"
                    onChange={handleInputChange}
                  >
                    <option>Select Ad Account</option>
                    {filterAdAccounts.map((account, i) => {
                      return (
                        <option key={i} value={account._id}>
                          {account.adAccountName}
                        </option>
                      );
                    })}
                  </select>

                  {adAccountAds.length > 0 && (
                    <div>
                      <label>Select Ads</label>
                      <div className="ad-account-ads">
                        {adAccountAds.map((ad, i) => (
                          <div key={i} className="ad">
                            <input
                              type="checkbox"
                              name="adFile"
                              id={ad._id}
                              value={ad._id}
                              title="Select Ad"
                              onChange={handleAdFileSelect}
                            />
                            {ad.adFile.map((file, i) => {
                              return (
                                <div className="ad-thumbnail" key={i}>
                                  {file.adType === 'picture' &&
                                    !file.adUrl.includes('mp4') && (
                                      <img src={file.adUrl} alt={file.adName} />
                                    )}
                                  {(file.adType === 'video' ||
                                    file.adUrl.includes('mp4')) && (
                                    <video
                                      src={file.adUrl}
                                      controls
                                      alt={file.adName}
                                    />
                                  )}
                                  {file.adUrl.includes('youtube.com') && (
                                    <iframe
                                      src={file.adUrl}
                                      title={file.adName}
                                      style={{ width: '100%' }}
                                      frameBorder="0"
                                    ></iframe>
                                  )}
                                  <div
                                    style={{
                                      fontSize: '13px',
                                      textAlign: 'left',
                                    }}
                                    className="label"
                                  >
                                    <label
                                      style={{
                                        fontSize: '13px',
                                        textAlign: 'left',
                                        wordBreak: 'break-word',
                                        padding: 0,
                                      }}
                                      htmlFor={file.adName}
                                    >
                                      {file.adName} - {file.adUrl}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="mini-form">
                    <div className="miniform-box">
                      <label htmlFor="date">Start Date </label>
                      <input
                        type="date"
                        name="startDate"
                        value={campaign.startDate}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="miniform-box">
                      <label htmlFor="date">End Date </label>
                      <input
                        type="date"
                        name="endDate"
                        value={campaign.endDate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="mini-form">
                    <div className="miniform-box">
                      <label htmlFor="time">Start Playtime </label>
                      <input
                        type="time"
                        timeformat="24h"
                        name="startTime"
                        value={campaign.startTime}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="miniform-box">
                      <label htmlFor="time">End Playtime </label>
                      <input
                        type="time"
                        name="endTime"
                        value={campaign.endTime}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="choosen_layout">
                  <h3>Layout Type</h3>
                  {filterAdAccounts.length ? (
                    screens
                      .filter((screen) =>
                        filterAdAccounts.some(
                          (filteredItem) =>
                            filteredItem.adScreen === screen._id,
                        ),
                      )
                      .map((screen, i) => (
                        <ScreenViewPort
                          key={i}
                          layout={screen.layout}
                          handleInputChange={handleInputChange}
                          checked={campaign.screenViewPort}
                        />
                      ))
                  ) : (
                    <p className="">Select a screen</p>
                  )}
                </div>
                <div className="mini-content">
                  <label htmlFor="days">
                    Play Campaign (Sunday - Saturday) choose to show campaign
                    everyday or select specific days.{' '}
                  </label>

                  <div className="mini-input">
                    <div className="cs-mi-flex">
                      <label htmlFor="sunday">Sunday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Sunday"
                        id="sunday"
                        onChange={handleCampaignChange}
                      />
                    </div>

                    <div className="cs-mi-flex">
                      <label htmlFor="monday">Monday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Monday"
                        id="monday"
                        onChange={handleCampaignChange}
                      />
                    </div>

                    <div className="cs-mi-flex">
                      <label htmlFor="tuesday">Tuesday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Tuesday"
                        id="tuesday"
                        onChange={handleCampaignChange}
                      />
                    </div>

                    <div className="cs-mi-flex">
                      <label htmlFor="wednesday">Wednesday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Wednesday"
                        id="wednesday"
                        onChange={handleCampaignChange}
                      />
                    </div>

                    <div className="cs-mi-flex">
                      <label htmlFor="thursday">Thursday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Thursday"
                        id="thursday"
                        onChange={handleCampaignChange}
                      />
                    </div>

                    <div className="cs-mi-flex">
                      <label htmlFor="friday">Friday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Friday"
                        id="friday"
                        onChange={handleCampaignChange}
                      />
                    </div>

                    <div className="cs-mi-flex">
                      <label htmlFor="saturday">Saturday</label>
                      <input
                        type="checkbox"
                        ref={inputRef}
                        name="campaignScheduleDays"
                        value="Saturday"
                        id="saturday"
                        onChange={handleCampaignChange}
                      />
                    </div>
                  </div>

                  <div className="range-group">
                    <div className="mini-range">
                      <label
                        htmlFor="videoAndImageDuration"
                        // style={{ fontSize: "1.2rem", marginTop: "1rem" }}
                      >
                        Video and Image duration (59secs) - Media such as video
                        uses the default video duration{' '}
                      </label>
                      <div className="show-value">
                        {show1 && (
                          <span
                            id="current-value"
                            style={{
                              left: `${campaign.videoAndImageDuration * 1.65}%`,
                            }}
                          >
                            {campaign.videoAndImageDuration}
                          </span>
                        )}
                      </div>
                      <input
                        type="range"
                        name="videoAndImageDuration"
                        id="custom-slider"
                        min="0"
                        max="60"
                        onChange={handleInputChange}
                        onMouseLeave={() => setShow1(false)}
                        onMouseOver={() => setShow1(true)}
                        value={parseInt(campaign.videoAndImageDuration)}
                      />
                    </div>
                    {/* <div className="mini-range">
                      <label
                        htmlFor="duration"
                        // style={{ fontSize: "1.2rem", marginTop: "1rem" }}
                      >
                        Image duration (60secs){" "}
                      </label>
                      <div className="show-value">
                        {show2 && (
                          <span
                            id="current-value"
                            style={{ left: `${campaign.imageDuration * 1.65}%` }}
                          >
                            {campaign.imageDuration}
                          </span>
                        )}
                      </div>
                      <input
                        type="range"
                        name="imageDuration"
                        id="custom-slider"
                        min="0"
                        max="60"
                        onChange={handleInputChange}
                        onMouseLeave={() => setShow2(false)}
                        onMouseOver={() => setShow2(true)}
                        value={parseInt(campaign.imageDuration)}
                      />
                    </div> */}
                  </div>

                  <button className="addnew-btn" title="Create Ad Campaign">
                    {submitLoading ? <Spinner /> : 'Create Ad Campaign'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCampaign;
