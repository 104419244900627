import {
  faCheckCircle,
  faEllipsisVertical,
  faUser,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ContentNavbar from '../components/ContentNavbar';
import SideBar from '../components/SideBar';
import Spinner from '../components/Spinner';
import ToastNotification from '../components/ToastNotification';

const AddUser = () => {
  const [token, setToken] = useState('' || localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [success, setSuccess] = useState(false);
  const [optionIndex, setOptionIndex] = useState(null);
  const [outsideClick, setOutsideClick] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [activating, setActivating] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    msg: '',
  });
  const [privilege, setPrivilege] = useState({
    privilege: '',
  });

  const btnRef = useRef(null);

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'));
  }, []);

  useEffect(() => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    if (success) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASEURL}/all-users`, { headers })
      .then((res) => {
        setUsers(res.data);
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
      });
  }, [token, success]);

  const handleActivate = (user) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_BASEURL}/account/activate/${user._id}`,
        {},
        { headers },
      )
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        setMessage({
          type: 'success',
          msg:
            user.userActiveStatus === 0
              ? `Deactivated ${user.firstName} ${user.lastName}`
              : `Activated ${user.firstName} ${user.lastName}`,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 6000);
      });
  };

  const handleSetAdmin = (user) => {
    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    setLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/users/privilege/${user._id}`,
        privilege,
        { headers },
      )
      .then((res) => {
        setTimeout(() => setLoading(false), 2000);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        setMessage({
          type: 'success',
          msg:
            user.privilege === 'admin' || privilege.privilege === 'superAdmin'
              ? `Deactivated ${user.firstName} ${user.lastName} as an admin`
              : `Activated ${user.firstName} ${user.lastName} as an admin`,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 5000);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 2000);
        setMessage({
          type: 'error',
          msg: err.response.data.message,
        });

        setTimeout(() => {
          setMessage({
            type: '',
            msg: '',
          });
        }, 6000);
      });
  };

  const handleCloseNotification = () => {
    setMessage({
      type: '',
      msg: '',
    });
  };

  const toggleOptions = (user, index) => {
    if (optionIndex === index) {
      return setOptionIndex(null);
    }
    setOptionIndex(index);

    if (user.privilege === 'admin') {
      setPrivilege({
        privilege: 'user',
      });
    } else if (user.privilege === 'user') {
      setPrivilege({
        privilege: 'admin',
      });
    } else {
      setPrivilege({
        privilege: '',
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (btnRef.current.className !== e.target.className) {
        setOutsideClick(true);
      } else {
        setOutsideClick(false);
      }

      setOutsideClick(null);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [btnRef]);

  useEffect(() => {
    if (outsideClick) {
      setOptionIndex(null);
    }
  }, [outsideClick]);

  return (
    <div>
      <SideBar />

      <ContentNavbar />

      {message.msg !== '' && (
        <ToastNotification
          type={message.type}
          text={message.msg}
          onclick={handleCloseNotification}
          icon={message.type === 'success' ? faCheckCircle : faWarning}
        />
      )}

      {loading && <Spinner />}

      <div className="main-content">
        <div className="content">
          <div className="first-title">All Users</div>

          <div className="second-title">User Settings</div>

          <div className="addnew-container">
            <div className="addnew-content">
              {users.map((user, index) => (
                <div
                  key={index}
                  title={
                    user.userActiveStatus === 0
                      ? 'Currently active'
                      : 'Currently not active'
                  }
                  className={
                    user.userActiveStatus === 0
                      ? 'user-box active'
                      : 'user-box inactive'
                  }
                >
                  <div className="user-details">
                    <div className="icon-box">
                      <FontAwesomeIcon icon={faUser} className="icon" />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <h4>
                        {user.firstName} {user.lastName}
                      </h4>
                    </div>
                    {user.privilege === 'admin' && (
                      <span className="admin-badge">Admin</span>
                    )}
                    {user.privilege === 'superAdmin' && (
                      <span className="admin-badge">Super Admin</span>
                    )}
                  </div>
                  <div className="btns">
                    <button
                      type="button"
                      title={
                        user.userActiveStatus === 1
                          ? `Activate ${user.firstName} ${user.lastName}`
                          : undefined
                      }
                      disabled={user.userActiveStatus === 0}
                      className={
                        user.userActiveStatus === 1
                          ? 'user-btn'
                          : 'disabled user-btn'
                      }
                      onClick={() => handleActivate(user)}
                    >
                      {activating ? 'Activating...' : 'Activate'}
                    </button>
                    <button
                      type="button"
                      title={
                        user.userActiveStatus === 0
                          ? `Deactivate ${user.firstName} ${user.lastName}`
                          : undefined
                      }
                      disabled={user.userActiveStatus === 1}
                      className={
                        user.userActiveStatus === 0
                          ? 'user-btn'
                          : 'disabled user-btn'
                      }
                      onClick={() => handleActivate(user)}
                    >
                      {activating ? 'Deactivating...' : 'Deactivate'}
                    </button>
                    {/* <button>
                      <FontAwesomeIcon icon={ faEllipsisVertical } className="icon" />
                    </button> */}
                    <div className="options-container">
                      <button
                        type="button"
                        ref={btnRef}
                        title="More"
                        onClick={() => toggleOptions(user, index)}
                        className="options-btn"
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          className="icon"
                        />
                      </button>
                      <ul
                        className={`${
                          optionIndex === index ? 'options show' : 'options'
                        }`}
                      >
                        <li>
                          <button
                            type="button"
                            title={
                              user.privilege === 'admin'
                                ? 'Deactivate as admin'
                                : 'Activate as admin'
                            }
                            onClick={() => {
                              handleSetAdmin(user);
                              setOptionIndex(null);
                            }}
                          >
                            {user.privilege === 'admin'
                              ? 'Deactivate as admin'
                              : 'Activate as admin'}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
