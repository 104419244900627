import React from 'react';

const ToggleSwitch = ({
  name,
  switchToggle,
  setSwitchToggle,
  handlerFunction,
}) => {
  //   const [switchToggle, setSwitchToggle] = useState(false);

  return (
    <>
      <label
        htmlFor={name}
        className={switchToggle ? `switch-toggle-bg` : `switch-toggle-bg false`}
      >
        <input
          type="checkbox"
          name={name}
          onChange={(e) => {
            // setSwitchToggle(!switchToggle);
            handlerFunction(!switchToggle);
          }}
          id={name}
          defaultChecked={switchToggle}
        />
        <span className={switchToggle ? undefined : `false`}></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
