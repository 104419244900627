import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import Login from "../pages/Login";
// import SignUp from "../pages/SignUp";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false || window.localStorage.getItem("user") === "true");
  
  useEffect(() => {
    setIsLoggedIn(JSON.parse(localStorage.getItem("user")));
  }, []);
  
  const user = { loggedIn: isLoggedIn };
  return user && user.loggedIn;
};
const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Login />;
};

export default ProtectedRoutes;
